<div class="md:w-full lg:w-[960px] flex flex-col items-center justify-center shadow rounded-xl bg-white p-4 m-4">
  <div class="py-2 h-16">
    <h1 class="title">{{ title }}</h1>
  </div>
  <div class="mt-1 w-full h-auto">
    <ng-content></ng-content>
  </div>
  <div class="py-2 mt-2 h-32" *ngIf="actions?.length">
    <div
      class="flex flex-row transition-height duration-1000 ease-out transform-gpu"
      *ngIf="actions"
    >
      <simplyhaus-button
        *ngFor="let action of actions"
        (click)="navigateTo(action.link)"
        [icon]="action.icon"
      >
        {{ action.name }}
      </simplyhaus-button>
    </div>
  </div>
</div>
