import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'simplyhaus-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  imageLoaded: boolean = false;
  @Input('url') set url(url: string) {
    // Preguntamos si existe un valor en la variable.
    if (url) {
      this.imageLoaded = false;
      this.loadImage(url);
    }
  }

  // Obtenemos una referencia hacia el tag "<img>" para poder manipularlo luego
  @ViewChild('image') image!: ElementRef;
  constructor() {}

  ngOnInit() {
    // cuando la imagen se carge en su totalidad.
    this.image.nativeElement.onload = () => {
      this.imageLoaded = true;
    };
  }
  // Función que utilizaremos para comenzar el proceso de carga de imagenes,
  // esta misma le proporcionara la dirección imagen la cual tiene que cargar.
  loadImage(urlImage) {
    this.image.nativeElement.src = urlImage;
  }
}
