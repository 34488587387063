export enum Role {
  GOD = 'GOD',
  /* Administrador general de la plataforma (NOSOTROS) */

  PLATFORM_ADMIN = 'PLATFORM_ADMIN',
  /* Encargado de plataforma por parte de la empresa (Inmobiliaria y Broker) */

  REAL_ESTATE_COLLABORATOR = 'REAL_ESTATE_COLLABORATOR',
  /* Trabajador de la inmobiliaria */

  BROKER_COLLABORATOR = 'BROKER_COLLABORATOR',
  /* Trabajador del broker */

  SELLER = 'SELLER',
  /* Vendedor */

  ADMINISTRATION_MANAGER = 'ADMINISTRATION_MANAGER',
  /* Gerente de operaciones */

  COMMERCIAL_MANAGER = 'COMMERCIAL_MANAGER',
  /* Gerente de comercial es el que tiene acceso a todo menos configuración de empresa */

  FINANCE_MANAGER = 'FINANCE_MANAGER',
  /* Gerente de finanzas ver procesos escriturados, acceso al dashboard y pagos */

  LEGAL_MANAGER = 'LEGAL_MANAGER',
  /* Administrador de documentación legal y templates. */

  LAWYER = 'LAWYER',
  /* Abogado, que tiene acceso a lo mismo que el legal manager,
  pero requiere aprobación del legal manager cuando edite un documento crítico */
}
