<!-- <nav class="eael-nav-menu-wrapper" style="position: inherit;"><ul id="menu-header" class="eael-simple-menu eael-simple-menu-dropdown-animate-to-top eael-simple-menu-indicator eael-simple-menu-horizontal eael-simple-menu-responsive"><li id="menu-item-492" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-492"><a href="https://simply-haus.com/propietarios/">Propietarios</a></li>
  <li id="menu-item-679" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-679"><a href="https://simply-haus.com/somos/">Quienes Somos</a></li>
  <li id="menu-item-806" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-806"><a href="https://simply-haus.com/se-un-hauser/">Se un Hauser</a></li>
  <li id="menu-item-324" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-324"><a href="#contacto">Contacto</a></li>
  <li id="menu-item-892" class="boton-menu menu-item menu-item-type-custom menu-item-object-custom menu-item-892"><a href="https://am.hostify.com/user/login">Inicio sesión propietarios</a></li>
  </ul></nav> -->

  <nav class="flex h-[114px] bg-black w-full fixed top-0 z-50 justify-around md:justify-center align-middle">
    <div class="w-[30%] md:w-[10%] flex">
      <div class="content-center items-center flex relative w-full flex-wrap">
        <div class="w-full text-center h-auto py-2">
          <a href="https://simply-haus.com">
            <img 
              width="800" 
              height="800" 
              src="https://simply-haus.com/wp-content/uploads/2023/08/simply_haus-2-1024x1024.png" 
              class="attachment-large size-large wp-image-67 max-w-[70%]" 
              alt="" 
              srcset="https://simply-haus.com/wp-content/uploads/2023/08/simply_haus-2-1024x1024.png 1024w, https://simply-haus.com/wp-content/uploads/2023/08/simply_haus-2-300x300.png 300w, https://simply-haus.com/wp-content/uploads/2023/08/simply_haus-2-150x150.png 150w, https://simply-haus.com/wp-content/uploads/2023/08/simply_haus-2-768x768.png 768w, https://simply-haus.com/wp-content/uploads/2023/08/simply_haus-2-1536x1536.png 1536w, https://simply-haus.com/wp-content/uploads/2023/08/simply_haus-2-2048x2048.png 2048w" 
              sizes="(max-width: 800px) 100vw, 800px"
            >
          </a>
        </div>
      </div>
    </div>
    <ul class="max-w-[1140px] hidden md:flex flex-row justify-between align-middle items-center">
      <li><a class="px-4 py-3 hover:bg-[#C8B44C] hover:no-underline hover:text-black" href="https://simply-haus.com/propietarios/">Propietarios</a></li>
      <li><a class="px-4 py-3 hover:bg-[#C8B44C] hover:no-underline hover:text-black" href="https://simply-haus.com/somos/">Quienes Somos</a></li>
      <li><a class="px-4 py-3 hover:bg-[#C8B44C] hover:no-underline hover:text-black" href="https://simply-haus.com/se-un-hauser/">Se un Hauser</a></li>
      <li><a class="px-4 py-3 hover:bg-[#C8B44C] hover:no-underline hover:text-black" href="https://simply-haus.com/#contacto">Contacto</a></li>
      <li><a class="px-4 py-3 hover:bg-[#C8B44C] hover:no-underline hover:text-black" href="https://am.hostify.com/user/login">Inicio sesión propietarios</a></li>
    </ul>
    <ul class="max-w-[1140px] hidden lg:flex flex-row justify-start align-middle items-center gap-6">
      <li><a class="hover:text-[#C8B44C]" href="https://www.facebook.com/people/Simply-HAUS/61550268013901/"><i class="fa fa-facebook-f"></i></a></li>
      <li><a class="hover:text-[#C8B44C]" href="https://www.instagram.com/simplyhaus_/"><i class="fa fa-instagram"></i></a></li>
      <li><a class="hover:text-[#C8B44C]" href="https://www.linkedin.com/company/simply-haus/about/"><i class="fa fa-linkedin"></i></a></li>
    </ul>
    <div class="dropdown md:hidden">
      <input type="checkbox" id="my-dropdown" value="" name="my-checkbox">
      <label for="my-dropdown" data-toggle="dropdown">
         <i class="fa fa-bars text-white w-3.5 h-4 flex md:hidden flex-row justify-between align-middle items-center hover:cursor-pointer"></i>
      </label>
      <ul>
        <li><a href="https://simply-haus.com/propietarios/">Propietarios</a></li>
        <li><a href="https://simply-haus.com/somos/">Quienes Somos</a></li>
        <li><a href="https://simply-haus.com/se-un-hauser/">Se un Hauser</a></li>
        <li><a href="https://simply-haus.com/#contacto">Contacto</a></li>
        <li><a href="https://am.hostify.com/user/login">Inicio sesión propietarios</a></li>
      </ul>
    </div>
  </nav>
<tui-root>
  
  <simplyhaus-notifications></simplyhaus-notifications>
  
  <main role="main">
    <div class="flex min-h-full lg:overflow-auto ">
      <simplyhaus-side-navigation></simplyhaus-side-navigation>
      <div
        class="flex-grow flex-col w-auto  h-full transform-gpu transition-all ease-out duration-300"
      >
        <simplyhaus-header
          class="fixed w-full top-0 z-50 transform-gpu transition-all ease-out duration-300"
        ></simplyhaus-header>
        <div class="flex overflow-x-auto overflow-y-auto h-auto w-full">
          <section
            class="flex flex-row w-full h-full justify-center items-center"
            [ngClass]="{'mt-32':true}"
            [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig?.data?.title"
          >
            <router-outlet #o="outlet"></router-outlet>
          </section>
        </div>
      </div>
    </div>
  </main>
  <ng-container ngProjectAs="tuiOverContent">
    <!-- Content over app content -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverAlerts">
    <!-- Content over notifications -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>
  <!--
<div
  class="min-h-screen bg-no-repeat bg-cover bg-center"
  style="
    background-image: url('https://images.unsplash.com/photo-1486520299386-6d106b22014b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80');
  "
>
  <div class="flex justify-end">
    <div class="bg-white min-h-screen w-1/2 flex justify-center items-center">
      <div>
        <form>
          <div>
            <span class="text-sm text-slate-900">Welcome back</span>
            <h1 class="text-2xl font-bold">Login to your account</h1>
          </div>
          <div class="mt-5">
            <label class="block text-md mb-2" for="password">Password</label>
            <input
              class="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
              type="password"
              name="password"
              placeholder="password"
            />
          </div>
          <div class="my-3">
            <label class="block text-md mb-2" for="email">Email</label>
            <input
              class="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
              type="email"
              name="password"
              placeholder="email"
            />
          </div>
          <div class="flex justify-between">
            <div>
              <input class="cursor-pointer" type="radio" name="rememberme" />
              <span class="text-sm">Remember Me</span>
            </div>
            <span class="text-sm text-blue-700 hover:underline cursor-pointer"
              >Forgot password?</span
            >
          </div>
          <div class="">
            <button
              class="mt-4 mb-3 w-full bg-emerald-500 hover:bg-emerald-400 text-white py-2 rounded-md transition duration-100"
            >
              Login now
            </button>
            <div
              class="flex space-x-2 justify-center items-end bg-slate-700 hover:bg-slate-600 text-white py-2 rounded-md transition duration-100"
            >
              <img class="h-5 cursor-pointer" src="https://i.imgur.com/arC60SB.png" alt="" />
              <button>Or sign-in with google</button>
            </div>
          </div>
        </form>
        <p class="mt-8">
          Dont have an account?
          <span class="cursor-pointer text-sm text-blue-600"> Join free today</span>
        </p>
      </div>
    </div>
  </div>
</div> -->
</tui-root>
