import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'simplyhaus-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @Input()
  color: string = 'btn-dark';

  @Input()
  extraClass: string = '';

  @Input()
  badgeColor: string = 'red';

  @Input()
  type: string = 'normal';

  @Input()
  badge: string = '';

  @Input()
  icon?: any;

  @Input()
  isDisabled: boolean = false;

  @Input()
  iconSide?: 'left' | 'right' = 'left';

  @Input()
  click: void | undefined;

  constructor() {}

  ngOnInit(): void {}
}
