import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { getDistance, getTranslationDistance, preloadImages } from './utils/utils';
import { gsap } from 'gsap';

@Component({
  selector: 'simplyhaus-app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit {
  @Input() images: string[] = ['/assets/images/banks/BCHILE.png', '/assets/images/banks/BCI.png'];

  @ViewChildren('gridItem') gridItems!: QueryList<ElementRef>;

  constructor() {}

  async ngOnInit(): Promise<void> {
    await preloadImages();

    const tl = gsap.timeline({
      defaults: {
        ease: 'power3.inOut',
        duration: 1.2,
      },
    });

    this.gridItems.forEach((item, index) => {
      const siblings = this.gridItems.filter((i, idx) => idx !== index);
      const closestSibling = siblings.reduce((prev, current) => {
        const prevDistance = getDistance(prev.nativeElement, item.nativeElement);
        const currentDistance = getDistance(current.nativeElement, item.nativeElement);
        return currentDistance < prevDistance ? current : prev;
      });

      const { x, y } = getTranslationDistance(item.nativeElement, closestSibling.nativeElement);
      const z = (Math.atan2(y, x) * 180) / Math.PI;
      tl.fromTo(item.nativeElement, { x: 0, y: 0, rotation: 0 }, { x, y, z }, 0);
    });
  }
}
