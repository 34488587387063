<button
  *ngIf="type === 'normal'"
  [disabled]="isDisabled"
  (onClick)="(click)"
  class="hover:scale-95 hover:bg-black border-golden border my-2 transform-gpu transition-all ease-out duration-300 justify-items-center h-16 px-8 text-black hover:text-golden hover:shadow-md hover:no-underline"
>
  <fa-icon class="mx-2" *ngIf="icon && iconSide === 'left'" [icon]="['fas', icon]"> </fa-icon>
  <ng-content></ng-content>
  <fa-icon class="mx-2" *ngIf="icon && iconSide === 'right'" [icon]="['fas', icon]"> </fa-icon>
</button>

<button
  *ngIf="type === 'header'"
  class=" text-golden hover:scale-105 mx-2 p-2 rounded-full transform-gpu border-0 hover:bg-{{
    color
  }}-500 hover:shadow-lg transition-all duration-300 cursor-pointer"
  type="button"
>
  <fa-icon class="mx-2" [icon]="['fas', icon]"> </fa-icon>
  <div
    *ngIf="badge !== ''"
    class="absolute top-0 right-0 px-1.5 py-0.5 bg-{{ badgeColor }}-500 rounded-full text-xs"
  >
    {{ badge }}
  </div>
</button>
<button
  *ngIf="type === 'rounded'"
  [disabled]="isDisabled"
  (onClick)="(click)"
  class="hover:scale-95 hover:bg-black my-2 transform-gpu transition-all ease-out duration-300 justify-items-center p-2 text-black hover:text-golden hover:shadow-md hover:no-underline"
>
  <fa-icon class="p-2" [icon]="['fas', icon]"> </fa-icon>
  <div
    *ngIf="badge !== ''"
    class="absolute top-0 right-0 px-1.5 py-0.5 bg-{{ badgeColor }}-500 rounded-full text-xs"
  >
    {{ badge }}
  </div>
</button>
