<div
  class="flex flex-row rounded-xl bg-emerald-100 border-dashed w-80 h-52 m-3 border-2 border-emerald-300 justify-center"
>
  <input type="file" class="opacity-0 p-0 w-auto" (change)="addFile($event)" />
  <div class="flex flex-col items-center justify-center py-10 text-center">
    <svg
      class="w-6 h-6 mr-1 text-current-50"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
    <p class="m-0">Drag your files here or click in this area.</p>
  </div>
</div>
