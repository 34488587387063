<nz-upload
  nzListType="picture-card"
  [(nzFileList)]="fileList"
  [nzPreview]="handlePreview"
  nzMultiple="false"
  [nzShowButton]="fileList.length < 1"
  [nzCustomRequest]="handleUpload"
  (nzChange)="handleChange($event)"
>
  <div class="ml-2">
    <span nz-icon nzType="plus"></span>
    <div style="margin-top: 8px">imagen</div>
  </div>
</nz-upload>
<nz-modal
  [nzVisible]="previewVisible"
  [nzContent]="modalContent"
  [nzFooter]="null"
  (nzOnCancel)="previewVisible = false"
>
  <ng-template #modalContent>
    <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
  </ng-template>
</nz-modal>
