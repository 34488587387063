import { Directive, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { gsap } from 'gsap';

@Directive({
  selector: '[coreAnimation]',
})
export class CoreAnimationDirective {
  @Input() duration: number = 1;
  @Input() delay: number = 0;
  @Input() staggerDelay: number = 0;
  @Input() position: number = 0;

  @Output() complete: EventEmitter<null> = new EventEmitter();
  @Output() reverseComplete: EventEmitter<null> = new EventEmitter();
  protected timeline = gsap.timeline({
    paused: true,
    reversed: true,
    onComplete: () => this.complete.emit(),
    onReverseComplete: () => this.reverseComplete.emit(),
  });
  constructor(protected el: ElementRef, protected renderer: Renderer2) {
    this.timeline.eventCallback('onComplete', () => this.complete.emit());

    /* ({
      onComplete: _ => this.complete.emit(),
      onReverseComplete: _ => this.reverseComplete.emit(),
      paused:true,
      reversed:true
    }); */
  }

  protected animateIn() {
    if (this.timeline.isActive()) {
      this.timeline.kill();
    }
    this.timeline.play();
  }
}
