import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Inject,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationsService } from 'projects/simplyhaus-app/src/app/core/services/notifications.service';
import { CloudFile } from 'projects/simplyhaus-app/src/gql/graphql';
import { NzUploadFile, NzUploadListType } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { environment } from 'projects/simplyhaus-app/src/environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TuiPdfViewerOptions, TuiPdfViewerService } from '@taiga-ui/kit';
import { DomSanitizer } from '@angular/platform-browser';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { url } from 'inspector';
import { FileStorageService } from '@services/documents/firebase-storage.service';
import { FileUpload } from '@services/documents/models/file-upload.model';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core/core.module';

@Component({
  selector: 'simplyhaus-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent implements ControlValueAccessor {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  @Input() preview: boolean = false;
  @Input() autoUpload: boolean = false;
  @Input() loadingText: string = 'cargando imagen...';
  @Input() inputType: NzUploadListType = 'picture-card';
  @Input('title') textButton: string = '';
  picture: any;
  selectedFiles: FileList | undefined;
  currentFileUpload: FileUpload | undefined;
  percentage: number = 0;
  currentFileName: string | undefined = 'Seleccionar';
  currentDocument?: CloudFile;
  isDisabled: boolean | undefined;
  loading = false;
  avatarUrl?: string;

  ACTION_URL: string = `${environment.baseUrl}api/files/upload`;

  previewImage: string | undefined = '';
  previewVisible = false;
  onChange = (_: any) => {};
  onTouch = () => {};
  modalRef!: BsModalRef;

  constructor(
    private uploadService: FileStorageService,
    private notifications: NotificationsService,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
    @Inject(TuiPdfViewerService) private readonly pdfService: TuiPdfViewerService //  private modalService: BsModalService
  ) {}

  writeValue(currentDocument: any): void {
    console.log('WRITE VALUE', currentDocument);
    if (currentDocument != null) {
      this.currentDocument = currentDocument;
      this.avatarUrl = currentDocument.url;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  viewDoc(url) {
    window.open(url, '_blank')?.focus();
  }

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/webp' ||
        file.type === 'image/svg';
      const isPdf = file.type === 'application/pdf';

      if (!isJpgOrPng && !isPdf) {
        this.notifications.error('Debes subir unicamente imágenes o documentos!');
        observer.complete();
        return;
      }
      /* const isLt2M = file.size! / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.msg.error('Image must smaller than 2MB!');
        observer.complete();
        return;
      } */
      observer.next(isJpgOrPng || isPdf /* && isLt2M */);
      observer.complete();
    });

  previewFile = (file: NzUploadFile): Observable<string> =>
    new Observable((observer: Observer<string>) => {
      if (!file.response) {
        observer.complete();
        return;
      }
      console.log('PREVIEW FILE', file.response);
      this.avatarUrl = file.response.url;
      observer.next(file.response.url);
      observer.complete();
    });

  async handleChange(info: { file: NzUploadFile }) {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        console.log('UPLOADING FILE', info.file);
        break;
      case 'done':
        // Get this url from response in real world.
        console.log('DONE FILE', info.file);

        this.loading = false;
        this.avatarUrl = info.file.response.url;
        this.onTouch();
        this.onChange(info.file.response._id);
        break;
      case 'error':
        this.notifications.error('Error de red');
        this.loading = false;
        break;
    }
  }

  viewDocument(actions: PolymorpheusContent<TuiPdfViewerOptions>) {
    if (this.avatarUrl) {
      this.pdfService
        .open(this.sanitizer.bypassSecurityTrustResourceUrl(this.avatarUrl), {
          label: 'Previsualizar documento',
          actions,
        })
        .subscribe();
    }
  }
}
