import { Injectable } from '@angular/core';
import Notiflix from 'notiflix';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor() {
    Notiflix.Loading.init({
      customSvgUrl: '/assets/isotype.png',
      svgSize: '200px',
      backgroundColor: '#fff',
    });
  }

  showLoading(text?: string) {
    Notiflix.Loading.custom(text);
  }

  hideLoading() {
    Notiflix.Loading.remove();
  }

  error(message: string) {
    Notiflix.Notify.failure(message, { timeout: 3000 });
  }

  success(message: string) {
    Notiflix.Notify.success(message);
  }

  warning(message: string) {
    Notiflix.Notify.warning(message);
  }

  info(message: string) {
    Notiflix.Notify.info(message);
  }
}
