import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { GraphQLModule } from '../graphql.module';
import { routeAnimations, ROUTE_ANIMATIONS_ELEMENTS } from './animations/route.animations';
import { AnimationsService } from './animations/animations.service';
import { NotificationsService } from './services/notifications.service';
import { GtagModule } from 'angular-gtag';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { UiModule } from './ui/ui.module';
import { FadeInAnimationDirective } from './directives/fade-in.directive';

export { routeAnimations, ROUTE_ANIMATIONS_ELEMENTS, AnimationsService };

@NgModule({
  imports: [
    // Angular
    CommonModule,
    HttpClientModule,
    FormsModule,
    //Angular Firebase
    //AngularFireModule.initializeApp(environment.firebase),
    //AngularFireAuthModule,

    //GraphQL
    GraphQLModule,

    //Google analytics
    GtagModule.forRoot({ trackingId: 'G-QDGDSKsL3C3', trackPageviews: true }),

    //UI Components
    UiModule,
  ],
  declarations: [],
  providers: [NotificationsService, GoogleAnalyticsService],
  exports: [
    // angular
    FormsModule,

    //UI Components
    UiModule,
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
