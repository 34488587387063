import { Inject, Injectable } from '@angular/core';
import { AccessToken, Company } from '@gql/graphql';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { v4 as uuid } from 'uuid';

const CART = 'local_cart';
const MODELS = 'local_models_list';
const UNITS = 'local_units_list';
const BLACKLIST = 'local_blacklist_list';
const CURRENT_PROJECT_ID = 'current_project_id';
const CURRENT_ACCESS_TOKEN = 'current_access_token';
const CURRENT_CLIENT = 'current_client';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public setCurrentProjectId(projectId: string) {
    this.storage.set(CURRENT_PROJECT_ID, projectId);
  }

  public getCurrentProjectId(): any | null {
    return this.storage.get(CURRENT_PROJECT_ID) ? this.storage.get(CURRENT_PROJECT_ID) : null;
  }

  public setCurrentAccessToken(accessToken: AccessToken) {
    this.storage.set(CURRENT_ACCESS_TOKEN, accessToken);
  }

  public setCurrentCompany(company: any) {
    let currentToken = this.getCurrentAccessToken();
    currentToken.company = company;
    this.setCurrentAccessToken(currentToken);
  }

  public clearAccessToken() {
    this.storage.set(CURRENT_ACCESS_TOKEN, null);
  }

  public getCurrentAccessToken(): AccessToken {
    return this.storage.get(CURRENT_ACCESS_TOKEN) ? this.storage.get(CURRENT_ACCESS_TOKEN) : null;
  }

  public addBlackList(item: string) {
    let currentBlackList = this.getBlackList().filter(cItem => cItem != item);

    currentBlackList.push(item);
    this.storage.set(BLACKLIST, currentBlackList);
  }

  public existBlackList(item: string): boolean {
    return this.getBlackList().filter(cItem => cItem == item) != null;
  }

  public removeBlackList(item: string) {
    let currentBlackList = this.getBlackList().filter(cItem => cItem != item);
    this.storage.set(BLACKLIST, currentBlackList);
  }

  public getBlackList(): Array<string> {
    return this.storage.get(BLACKLIST) ? this.storage.get(BLACKLIST) : [];
  }

  public setCurrentClient(client: any) {
    this.storage.set(CURRENT_CLIENT, client);
  }

  public clearCurrentClient() {
    this.storage.set(CURRENT_CLIENT, null);
  }

  public getCurrentClient(): any | null {
    return this.storage.get(CURRENT_CLIENT) ? this.storage.get(CURRENT_CLIENT) : null;
  }
}
