<tui-input formControlName="address" tuiHintContent="Dirección" [tuiTextfieldCleaner]="true">
  <span class="text-[#62718D] text-xs">{{ field.label }}</span>
  <!-- <fa-icon
    icon="map-marker-alt"
    class="pointer-events-none w-20 h-20 absolute top-1/2 transform -translate-y-1 left-3 align-middle text-[#000000]/[0.2]"
  ></fa-icon> -->
  <input
    tuiTextfield
    type="text"
    ngx-google-places-autocomplete
    [placeholder]="field.placeholder"
    #placesRef="ngx-places"
    [options]="AutocompleteOptions"
    class="invalid:border-pink-500 invalid:text-pink-600 p-3 pl-4 w-full bg-white border text-base border-slate-200 rounded-none focus:border-slate-400 focus:outline-none focus:bg-white mb-2 placeholder-[#000000]/[0.2]"
    (onAddressChange)="handleAddressChange($event)"
  />
</tui-input>
