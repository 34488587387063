import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headerRolesPipe',
})
export class HeaderRolesPipe implements PipeTransform {
  public transform(value: any) {
    let valueFinal = '';
    for (var role in value) {
      if (!value[role].includes('COLLABORATOR')) {
        valueFinal = value[role];
      }
    }

    switch (valueFinal) {
      case 'PLATFORM_ADMIN':
        return 'Administrador de plataforma';
      case 'SELLER':
        return 'Vendedor';
      case 'DRIVER':
        return 'Conductor';
      case 'TECH_MANAGER':
        return 'Gerente técnico';
      case 'COMMERCIAL_MANAGER':
        return 'Gerente comercial';
      case 'FINANCE_MANAGER':
        return 'Gerente de finanzas';
      case 'ADMINISTRATION_MANAGER':
        return 'Gerente de Operaciones';
      default:
        return 'Usuario';
    }
  }
}
