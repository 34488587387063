import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  OnDestroy,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import { gsap } from 'gsap';
import { CoreAnimationDirective } from './core-animation.directive';

@Directive({
  selector: '[ngIfAnimation]',
})
export class NgIfAnimationDirective implements AfterViewInit, OnDestroy {
  @Input() duration: number = 0.3;
  @Input() delay: number = 0.3;
  @Input() staggerDelay: number = 0.1;
  @Input() position: number = 0;

  private elem: ElementRef<any>;
  private animation!: gsap.core.Tween;

  constructor(protected el: ElementRef, protected renderer: Renderer2, protected ngZone: NgZone) {
    this.elem = this.el.nativeElement;
  }

  @Input() set ngIfAnimation(condition: boolean) {
    if (condition) {
      // Handle the elem becoming visible
      this.animateElementIn();
    } else {
      // Handle the element becoming hidden
      this.animateElementOut();
    }
  }

  ngOnDestroy() {
    // Clean up animations or resources when the directive is destroyed
    if (this.animation) {
      this.animation.kill();
    }
  }

  ngAfterViewInit() {
    // perform animation
    this.renderer.setStyle(this.elem, 'opacity', '0');
    /* this.renderer.setStyle(this.elem, 'display', 'none');
    gsap.set(this.elem, { scale: 0.8, autoAlpha: 0, display: 'none' }); */
    //this.animateElementIn();
  }

  private animateElementIn() {
    this.animation = gsap.fromTo(
      this.elem,
      { scale: 0.8, autoAlpha: 0, display: 'none' },
      {
        scale: 1,
        autoAlpha: 1,
        display: 'block',
        ease: 'expo.in',
        duration: this.duration,
        delay: this.delay + this.staggerDelay * this.position,
      }
    );
  }

  private animateElementOut() {
    this.animation = gsap.fromTo(
      this.elem,
      { scale: 1, autoAlpha: 1, display: 'block' },
      {
        scale: 0.8,
        autoAlpha: 0,
        display: 'none',
        ease: 'expo.out',
        duration: this.duration,
        delay: this.delay /* + this.staggerDelay * this.position */,
      }
    );
  }
}
