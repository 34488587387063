import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { gsap } from 'gsap';
@Directive({
  selector: '[simplyhaus-image-loader]',
})
export class ProgressiveImageLoaderDirective implements AfterContentInit, OnDestroy {
  private nativeElement!: HTMLElement;
  private cancelOnError!: Function;
  private cancelOnLoad!: Function;
  @Input() fallback: string = '';
  @Input() opacity: number = 1;
  @Output() emitOnError: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterContentInit() {
    this.registerEvents();
    gsap.set(this.nativeElement, {
      autoAlpha: 0,
      webkitFilter: 'brightness(10)',
      filter: 'brightness(10)',
      zoom: 1.4,
    });
  }

  registerEvents() {
    this.nativeElement = this.el.nativeElement;
    this.onError = this.onError.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.cancelOnError = this.renderer.listen(this.nativeElement, 'error', this.onError);
    this.cancelOnLoad = this.renderer.listen(this.nativeElement, 'load', this.onLoad);
  }

  private onError() {
    if (this.nativeElement.getAttribute('src') !== this.fallback) {
      this.renderer.setAttribute(this.nativeElement, 'src', this.fallback);
      this.emitOnError.emit();
    } else {
      this.removeOnLoadEvent();
    }
  }

  private onLoad() {
    this.removeOnLoadEvent();
    gsap.to(this.nativeElement, {
      autoAlpha: this.opacity,
      duration: 0.7,
      delay: 0.5,
      ease: 'expo.out',
      webkitFilter: 'brightness(1)',
      filter: 'brightness(1)',
      zoom: 1.2,
    });
  }

  private removeErrorEvent() {
    if (this.cancelOnError) {
      this.cancelOnError();
    }
  }

  private removeOnLoadEvent() {
    if (this.cancelOnLoad) {
      this.cancelOnLoad();
    }
  }

  ngOnDestroy() {
    this.removeErrorEvent();
    this.removeOnLoadEvent();
  }
}
