import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ThousandsPipe } from './pipes/thousands.pipe';
import { ShortCurrencyPipe } from './pipes/short-currency.pipe';
import { TagifyModule } from 'ngx-tagify';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { DynamicScriptLoaderService } from 'projects/simplyhaus-app/src/app/shared/services/dynamic-script-loader/dynamic-script-loader.service';
import { CalendarModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CountUpModule } from 'ngx-countup';
import { NgxPopperModule } from 'ngx-popper';
import { MomentModule } from 'ngx-moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { BottomsheetComponent } from './components/bottomsheet/bottomsheet.component';
import { EditableModule } from '@ngneat/edit-in-place';
import { GalleryModule } from 'ng-gallery';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NgxEchartsModule } from 'ngx-echarts';
import { UiModule } from 'projects/simplyhaus-app/src/app/core/ui/ui.module';
import { AccessControlDirective } from './directives/access-control.directive';
import { EmptyComponent } from './components/empty/empty.component';
import { DropZoneComponent } from './components/drop-zone/drop-zone.component';
import { ErrorComponent } from './components/error-component/error.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { CollaboratorRolesPipe } from './pipes/users/collaborator-roles.pipe';

import {
  TuiAccordionModule,
  TuiDataListWrapperModule,
  TuiFieldErrorPipeModule,
  TuiInputCountModule,
  TuiInputDateModule,
  TuiInputFilesModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputPhoneInternationalModule,
  TuiInputTagModule,
  TuiMultiSelectModule,
  TuiSelectModule,
  TuiAvatarModule,
  TuiTextAreaModule,
  TuiCheckboxLabeledModule,
  TuiProgressModule,
  TuiInputSliderModule,
  TuiRadioListModule,
  TuiStepperModule,
} from '@taiga-ui/kit';
import { TuiTableModule } from '@taiga-ui/addon-table';
import {
  TuiGroupModule,
  TuiErrorModule,
  TuiDataListModule,
  TuiLabelModule,
  TuiTextfieldControllerModule,
  TuiHintBoxModule,
  TuiHintModule,
  TuiButtonModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { MultipleImagesComponent } from './components/file-manager/components/multiple-images/multiple-images.component';
import { SingleImageComponent } from './components/file-manager/components/single-image/single-image.component';
import { PDFUploaderComponent } from './components/file-manager/components/pdf-uploader/pdf-uploader.component';
import { FadeInAnimationDirective } from '@app/core/directives/fade-in.directive';

// Note we need a separate function as it's required
// by the AOT compiler.

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

export function playerFactory() {
  return player;
}

export function momentAdapterFactory() {
  return adapterFactory(moment);
}
@NgModule({
  declarations: [
    FileUploadComponent,
    ImageLoaderComponent,
    BottomsheetComponent,
    EmptyComponent,
    ErrorComponent,
    FileManagerComponent,
    MultipleImagesComponent,
    SingleImageComponent,
    PDFUploaderComponent,

    /* Pipes */
    ThousandsPipe,
    ShortCurrencyPipe,
    CollaboratorRolesPipe,

    AccessControlDirective,
    FadeInAnimationDirective,
    DropZoneComponent,
  ],
  imports: [
    //vendor
    FullCalendarModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    ModalModule.forRoot(),
    TagifyModule.forRoot(),
    GooglePlaceModule,
    CalendarModule,
    NgbModule,
    DragDropModule,
    CountUpModule,
    NgxPopperModule.forRoot(),
    MomentModule,
    FontAwesomeModule,
    LottieModule.forRoot({ player: playerFactory }),
    EditableModule,
    GalleryModule,
    NzBadgeModule,
    NzTagModule,
    NzIconModule,
    NzAvatarModule,
    NzStatisticModule,
    NzGridModule,
    NzBreadCrumbModule,
    ImageCropperModule,
    NzUploadModule,
    NzModalModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    UiModule,

    TuiInputFilesModule,
    TuiAccordionModule,
    TuiTableModule,
    TuiGroupModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiSelectModule,
    TuiMultiSelectModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiLabelModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    TuiHintBoxModule,
    TuiHintModule,
    TuiInputNumberModule,
    TuiInputCountModule,
    TuiButtonModule,
    TuiInputTagModule,
    TuiInputPhoneInternationalModule,
    TuiInputDateModule,
    TuiAvatarModule,
    TuiTextAreaModule,
    TuiSvgModule,
    TuiCheckboxLabeledModule,
    TuiProgressModule,
    TuiInputSliderModule,
    TuiRadioListModule,
    TuiStepperModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadComponent,
    HttpClientModule,
    ModalModule,
    TagifyModule,
    GooglePlaceModule,
    CalendarModule,
    NgbModule,
    DragDropModule,
    CountUpModule,
    NgxPopperModule,
    MomentModule,
    FontAwesomeModule,
    LottieModule,
    EditableModule,
    GalleryModule,
    ImageLoaderComponent,
    BottomsheetComponent,
    EmptyComponent,
    ErrorComponent,
    FileManagerComponent,
    MultipleImagesComponent,
    SingleImageComponent,
    PDFUploaderComponent,

    /* Pipes */
    ThousandsPipe,
    ShortCurrencyPipe,
    CollaboratorRolesPipe,

    NzBadgeModule,
    NzTagModule,
    NzIconModule,
    NzAvatarModule,
    NzStatisticModule,
    NzGridModule,
    NzBreadCrumbModule,
    ImageCropperModule,
    NzUploadModule,
    NzModalModule,
    NgxEchartsModule,
    UiModule,
    TuiInputFilesModule,
    TuiAccordionModule,
    TuiTableModule,
    TuiGroupModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiSelectModule,
    TuiMultiSelectModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiLabelModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    TuiHintBoxModule,
    TuiHintModule,
    TuiInputNumberModule,
    TuiInputCountModule,
    TuiButtonModule,
    TuiInputTagModule,
    TuiInputPhoneInternationalModule,
    TuiInputDateModule,
    TuiAvatarModule,
    TuiTextAreaModule,
    TuiSvgModule,
    TuiCheckboxLabeledModule,
    TuiProgressModule,
    TuiInputSliderModule,
    TuiRadioListModule,
    TuiStepperModule,

    AccessControlDirective,
    FadeInAnimationDirective,
    DropZoneComponent,
  ],
  providers: [DynamicScriptLoaderService],
})
export class SharedModule {}
