<ng-container>
  <li class="flex items-stretch justify-center align-middle group">
    <a
      [routerLink]="item.url"
      routerLinkActive="bg-black shadow-md text-white"
      class="flex flex-row items-center hover:scale-95 hover:bg-black my-2 transform-gpu transition-all ease-out duration-300 justify-items-center h-16 px-8 text-black hover:text-[#C8B44C] hover:shadow-md hover:no-underline"
    >
      <span class="flex items-center align-middle justify-center text-base">
        <fa-icon
          class="my-0 h-8 w-8 text-center items-center transform-gpu transition-all ease-out duration-300"
          [icon]="['fas', item.icon]"
          [tuiHint]="template"
          tuiHintDirection="right"
        ></fa-icon>

        <ng-template #template>{{ item.label }}</ng-template>
      </span>
      <span
        class="ml-3 transform-gpu transition-all ease-out duration-300"
        [ngClass]="{
          'opacity-0 hidden': (sidebar.isCollapsed | async),
          'opacity-100': !(sidebar.isCollapsed | async)
        }"
        >{{ item.label }}
      </span>
    </a>
  </li>
</ng-container>
