import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StepsService {
  // Observable string sources
  private indexChangedSource = new BehaviorSubject<number>(0);
  // Observable string streams

  public currentIndex = this.indexChangedSource;
  public indexChanged$ = this.indexChangedSource.asObservable();
  // Observable string sources
  private titleSource = new BehaviorSubject<string>('Nuevo proyecto');
  // Observable string streams
  public titleChanged$ = this.titleSource.asObservable();

  constructor() {}

  // Service message commands
  changeIndex(change: number) {
    this.indexChangedSource.next(change);
  }

  changeTitle(title: string) {
    this.titleSource.next(title);
  }
}
