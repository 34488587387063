import { Component, Input, NgZone, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'simplyhaus-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss'],
})
export class EmptyComponent implements OnInit {
  private animationsMap: Map<string, AnimationItem> = new Map();
  @Input()
  message?: string;
  options: AnimationOptions = {
    path: '/assets/animations/empty.json',
  };
  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {}
  animationCreated(
    name: string,
    animationItem: AnimationItem,
    autoStart: boolean = true,
    loop: boolean = true
  ): void {
    animationItem.autoplay = autoStart;
    animationItem.loop = loop;
    this.animationsMap.set(name, animationItem);
  }

  stop(name: string) {
    this.ngZone.runOutsideAngular(() => {
      this.animationsMap.get(name)?.goToAndStop(this.animationsMap.get(name)!.totalFrames - 1);
    });
  }

  start(name: string) {
    this.ngZone.runOutsideAngular(() => {
      this.animationsMap.get(name)?.play();
    });
  }
}
