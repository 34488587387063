<nz-upload
  nzListType="text"
  [(nzFileList)]="fileList"
  [nzPreview]="handlePreview"
  nzMultiple="false"
  [nzShowButton]="fileList.length < 1"
  [nzCustomRequest]="handleUpload"
  (nzChange)="handleChange($event)"
>
  <simplyhaus-button icon="upload" *ngIf="!fileList.length">Subir PDF</simplyhaus-button>
</nz-upload>
<!-- <ng-container #viewFile
  ><simplyhaus-button icon="file" (click)="viewDocument()">Ver archivo</simplyhaus-button>
</ng-container> -->
<!-- <ng-template [(tuiPdfViewer)]="open">
  <iframe [src]="previewPDF" class="iframe"></iframe>
</ng-template> -->
<!--
<ng-template #actions let-content="content">
  <a tuiButton shape="rounded" size="s" download [href]="content"> Descargar </a>
</ng-template> -->
