import { Injectable } from '@angular/core';
import { AuthService } from 'projects/simplyhaus-app/src/app/features/auth/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import RealEstateAdminMenu from '../ui/components/side-navigation/data/real-estate/real-estate-admin.json';
import RealEstateSellerMenu from '../ui/components/side-navigation/data/real-estate/real-estate-seller.json';
import RealEstateLegalManagerMenu from '../ui/components/side-navigation/data/real-estate/real-estate-legal-manager.json';
import RealEstateCommercialManagerMenu from '../ui/components/side-navigation/data/real-estate/real-estate-commercial-manager.json';
import RealEstateAdministrationManagerMenu from '../ui/components/side-navigation/data/real-estate/real-estate-administration-manager.json';
import BrokerAdminMenu from '../ui/components/side-navigation/data/broker/broker-admin.json';
import BrokerSellerMenu from '../ui/components/side-navigation/data/broker/broker-seller.json';
import BrokerLegalManagerMenu from '../ui/components/side-navigation/data/broker/broker-legal-manager.json';
import BrokerCommercialManagerMenu from '../ui/components/side-navigation/data/broker/broker-commercial-manager.json';
import BrokerAdministrationManagerMenu from '../ui/components/side-navigation/data/broker/broker-administration-manager.json';
import { AccessToken } from 'projects/simplyhaus-app/src/gql/graphql';
import { Role } from 'projects/simplyhaus-app/src/app/core/enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class SideNavigationService {
  currentToken: any;

  private showSidebarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private collapseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private showNotificationPanelSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private authService: AuthService) {
    this.refreshToken();
    this.showSidebarSubject.next(this.currentToken != null);
  }

  hide() {
    this.refreshToken();
    this.showSidebarSubject.next(false);
  }

  show() {
    this.refreshToken();
    this.showSidebarSubject.next(true);
  }

  collapse() {
    this.collapseSubject.next(true);
  }

  toggleCollapse() {
    this.collapseSubject.next(!this.collapseSubject.value);
  }

  extend() {
    this.collapseSubject.next(false);
  }

  showNotifications() {
    this.showNotificationPanelSubject.next(true);
  }

  hideNotifications() {
    this.showNotificationPanelSubject.next(false);
  }

  get isCollapsed() {
    return this.collapseSubject.asObservable();
  }

  get showNotificationsPanel() {
    return this.showNotificationPanelSubject.asObservable();
  }

  get showSidebar() {
    return this.showSidebarSubject.asObservable();
  }

  toggle() {
    this.refreshToken();
    this.showSidebarSubject.next(!this.showNotificationPanelSubject.value);
  }

  refreshToken() {
    this.currentToken = this.authService.getCurrentAccessToken();
  }

  logout() {
    this.authService.logout();
    this.hide();
  }

  getMenu(): any {
    const accessToken = this.currentToken as AccessToken;
    const roles = accessToken.user.roles;
    if (roles.includes(Role.REAL_ESTATE_COLLABORATOR)) {
      if (roles.includes(Role.SELLER)) {
        return RealEstateSellerMenu;
      } else if (roles.includes(Role.PLATFORM_ADMIN)) {
        return RealEstateAdminMenu;
      } else if (roles.includes(Role.ADMINISTRATION_MANAGER)) {
        return RealEstateAdministrationManagerMenu;
      } else if (roles.includes(Role.COMMERCIAL_MANAGER)) {
        return RealEstateCommercialManagerMenu;
      } else if (roles.includes(Role.LEGAL_MANAGER)) {
        return RealEstateLegalManagerMenu;
      }
    } else if (roles.includes(Role.BROKER_COLLABORATOR)) {
      if (roles.includes(Role.SELLER)) {
        return BrokerSellerMenu;
      } else if (roles.includes(Role.PLATFORM_ADMIN)) {
        return BrokerAdminMenu;
      } else if (roles.includes(Role.ADMINISTRATION_MANAGER)) {
        return BrokerAdministrationManagerMenu;
      } else if (roles.includes(Role.COMMERCIAL_MANAGER)) {
        return BrokerCommercialManagerMenu;
      } else if (roles.includes(Role.LEGAL_MANAGER)) {
        return BrokerLegalManagerMenu;
      }
    }
  }
}
