import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'projects/simplyhaus-app/src/app/features/auth/services/auth.service';
import { Role } from 'projects/simplyhaus-app/src/app/core/enums/role.enum';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { SidenavService } from './sidenav.service';

@Component({
  selector: 'simplyhaus-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  expanded: boolean = false;
  currentToken: any = null;
  private animationsMap: Map<string, AnimationItem> = new Map();

  dashboardOptions: AnimationOptions = {
    path: '/assets/animations/dashboard.json',
  };
  projectsOptions: AnimationOptions = {
    path: '/assets/animations/building.json',
  };
  calendarOptions: AnimationOptions = {
    path: '/assets/animations/calendar.json',
  };
  processOptions: AnimationOptions = {
    path: '/assets/animations/process_list.json',
  };
  contactsOptions: AnimationOptions = {
    path: '/assets/animations/contacts.json',
  };
  paymentsOptions: AnimationOptions = {
    path: '/assets/animations/payments.json',
  };
  configOptions: AnimationOptions = {
    path: '/assets/animations/config.json',
  };
  logoutOptions: AnimationOptions = {
    path: '/assets/animations/logout.json',
  };
  constructor(
    private authService: AuthService,
    public nav: SidenavService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  animationCreated(
    name: string,
    animationItem: AnimationItem,
    autoStart: boolean = true,
    loop: boolean = true
  ): void {
    animationItem.autoplay = autoStart;
    animationItem.loop = loop;
    animationItem.setSpeed(1.5);
    this.animationsMap.set(name, animationItem);
  }

  onEnterFrame(name: string, animationItem: AnimationItem): void {}

  stop(name: string) {
    this.ngZone.runOutsideAngular(() => {
      this.animationsMap.get(name)?.goToAndStop(this.animationsMap.get(name)!.totalFrames - 1);
    });
  }

  start(name: string) {
    this.ngZone.runOutsideAngular(() => {
      this.animationsMap.get(name)?.play();
    });
  }

  mouseEnter(name: string) {
    const anim = this.animationsMap.get(name);
    if (anim) {
      this.ngZone.runOutsideAngular(() => {
        anim.setDirection(1);
        anim.play();
      });
    }
  }

  mouseLeave(name: string) {
    const anim = this.animationsMap.get(name);
    if (anim) {
      this.ngZone.runOutsideAngular(() => {
        anim.setDirection(-1);
        anim.play();
      });
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentToken = this.authService.getCurrentAccessToken();
      }
    });
  }

  logout() {
    this.authService.logout();
    this.nav.hide();
  }

  isRealEstateAdmin() {
    let roles = this.currentToken?.user?.roles as string[];
    return roles
      ? roles.includes(Role.PLATFORM_ADMIN) && roles.includes(Role.REAL_ESTATE_COLLABORATOR)
      : false;
  }

  isRealEstateSeller() {
    let roles = this.currentToken?.user?.roles as string[];
    return roles
      ? roles.includes(Role.SELLER) && roles.includes(Role.REAL_ESTATE_COLLABORATOR)
      : false;
  }

  isBrokerAdmin() {
    let roles = this.currentToken?.user?.roles as string[];
    return roles
      ? roles.includes(Role.PLATFORM_ADMIN) && roles.includes(Role.BROKER_COLLABORATOR)
      : false;
  }

  isBrokerSeller() {
    let roles = this.currentToken?.user?.roles as string[];
    return roles ? roles.includes(Role.SELLER) && roles.includes(Role.BROKER_COLLABORATOR) : false;
  }
}
