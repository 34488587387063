import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DocumentStorage } from './models/document.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  readonly API_BASE: String = environment.baseUrl + 'api/documents';

  constructor(private httpClient: HttpClient) {}

  public createDocument(document: DocumentStorage) {
    return this.httpClient.post<DocumentStorage>(`${this.API_BASE}`, document);
  }
}
