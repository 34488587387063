import { ProgressHelperService } from './progress-helper.service';

export enum UiState {
  ACTIVE = 'active',
  COMPLETE = 'complete',
}

export enum Status {
  PENDING = 'pendiente',
  IN_PROGRESS = 'en progreso',
  COMPLETED = 'completado',
}

export class UiHelper {
  public itemProgressList: { stepIndex: number; status: string; title: string }[] = [];
  public activeIndex = 0;

  constructor(protected progressHelper: ProgressHelperService) {}

  protected completeLastStep() {
    this.itemProgressList[this.activeIndex].status = Status.COMPLETED;
  }

  protected undoLastComplete() {
    this.itemProgressList[this.activeIndex].status = Status.IN_PROGRESS;
  }

  protected switchStatusNext(index): void {
    for (let i: number = 0; i < index; i++) {
      this.itemProgressList[i].status = Status.COMPLETED;
    }
    this.itemProgressList[index].status = Status.IN_PROGRESS;
  }

  protected switchStatusPrev(index) {
    for (let i: number = index + 1; i < this.itemProgressList.length; i++) {
      this.itemProgressList[i].status = Status.PENDING;
    } /*
    this.itemProgressList[this.activeIndex + 1].status = Status.PENDING; */
    this.itemProgressList[index].status = Status.IN_PROGRESS;
  }
}
