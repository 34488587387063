import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'simplyhaus-side-navigation-section',
  templateUrl: './side-navigation-section.component.html',
  styleUrls: ['./side-navigation-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationSectionComponent implements OnInit {
  @Input() section: any;
  constructor() {}

  ngOnInit(): void {}
}
