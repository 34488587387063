// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC5FtA2DXGkXmYUJdIIvuq5TkemnNt9U6k',
    authDomain: 'simplyhaus-dev.firebaseapp.com',
    projectId: 'simplyhaus-dev',
    storageBucket: 'simplyhaus-dev.appspot.com',
    messagingSenderId: '37962672810',
    appId: '1:37962672810:web:48248e4b7f3e78521b7ed3',
    measurementId: 'G-3J1TTQ76YN',
  },
  // baseUrl: 'http://localhost:3003/',
  baseUrl: 'https://api.simply-haus.com/',
  baseWs: 'wss://api.simply-haus.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
