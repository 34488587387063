import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'simplyhaus-document-uploader',
  templateUrl: './document-uploader.component.html',
  styleUrls: ['./document-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentUploaderComponent implements OnInit {
  @Input()
  title: string = '';

  @Input()
  icon: string = 'id';

  @Input()
  isDefined: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
