import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SideNavigationService } from '../../../services/side-navigation.service';
import { AuthService } from '@app/features/auth/services/auth.service';
import { Observable } from 'rxjs';
import { User } from '@gql/graphql';

@Component({
  selector: 'simplyhaus-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  me$ = new Observable<User | null>();
  constructor(public sidebar: SideNavigationService, private auth: AuthService) {}

  ngOnInit() {
    this.me$ = this.auth.currentUser$;
  }

  showNotifications() {
    this.sidebar.showNotifications();
  }

  logout() {
    this.sidebar.logout();
  }
}
