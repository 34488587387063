import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'simplyhaus-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements OnInit {
  @Input()
  color: string = 'blue';

  @Input()
  type: string = 'text';

  @Input()
  icon?: any;

  @Input()
  iconSide?: string = 'left';

  constructor() {}

  ngOnInit(): void {}
}
