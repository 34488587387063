import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormInput } from '@app/features/playground/components/dynamic-form/models/form-input';
import { CreateAddressInput } from '@gql/graphql';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subscription } from 'rxjs';

@Component({
  selector: 'simplyhaus-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AddressInputComponent implements OnInit {
  @Input() field!: FormInput;

  @Output()
  onAddressSelected = new EventEmitter<CreateAddressInput>();
  @ViewChild('placesRef') placesRef!: GooglePlaceDirective;

  AutocompleteOptions: any = {
    componentRestrictions: { country: 'cl' },
  };

  private addressInput?: CreateAddressInput;
  @Input() control: FormControl = new FormControl('');
  subscription = new Subscription();
  constructor() {}

  ngOnInit() {
    /* 
    this.subscription.add(this.control.valueChanges.pipe().subscribe()); */
  }

  public async handleAddressChange(address: Address) {
    const streetName = this.getNameByType('route', address);
    const streetNumber = this.getNameByType('street_number', address) ?? 'S/N';
    const city = this.getNameByType('locality', address);
    const country = this.getNameByType('country', address);
    const addressClean = `${streetName ?? ''} ${streetNumber ?? ''}, ${city ?? ''}`;
    this.addressInput = {
      address: addressClean,
      city,
      streetName,
      streetNumber,
      country,
      location: {
        type: 'Point',
        coordinates: [address.geometry.location.lng(), address.geometry.location.lat()],
      },
    };
    this.onAddressSelected.emit(this.addressInput);
    this.control.patchValue(addressClean);
  }

  getNameByType(type: string, address: Address) {
    return address.address_components.find(component => component.types.includes(type))?.long_name;
  }
}
