import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, split, from } from '@apollo/client/core';
import { getOperationAST } from 'graphql';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-angular/http';

import { environment } from '../environments/environment';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { onError } from '@apollo/client/link/error';
import { NotificationsService } from './core/services/notifications.service';
//const uri = 'https://api.elmercadoinmobiliario.cl:3000/graphql'; // <-- add the URL of the GraphQL server here
const uri = environment.baseUrl + 'graphql'; // <-- add the URL of the GraphQL server here

const cache = new InMemoryCache();
export function createApollo(
  httpLink: HttpLink,
  notifications: NotificationsService
): ApolloClientOptions<any> {
  // Create an http link:
  //const GRAPH_QL_ENDPOINT = 'ws://api.elmercadoinmobiliario.cl/graphql';
  const GRAPH_QL_ENDPOINT = `${environment.baseWs}graphql`;
  const http = httpLink.create({
    uri: uri,
  });
  const linkError = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        //notifications.error(message);
      });

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      //notifications.error(networkError.message);
    }
  });

  const subscriptionClient = new SubscriptionClient(GRAPH_QL_ENDPOINT, {
    reconnect: true,
    connectionParams: {
      Authorization: null,
    },
    connectionCallback: error => {
      console.log('connectionCallback:', error);
    },
  });

  subscriptionClient.onConnected(data => {
    console.log('On connected!');
  });
  subscriptionClient.onDisconnected(data => {
    console.log('On disconnected!');
  });
  const wsLink = new WebSocketLink(subscriptionClient);

  const link = split(
    ({ query }) => {
      const data = getMainDefinition(query);
      return data.kind === 'OperationDefinition' && data.operation === 'subscription';
    },
    wsLink,
    http
  );

  return {
    link: from([linkError, link]),
    cache: new InMemoryCache({
      typePolicies: {
        Project: {
          fields: {
            currentViewers: {
              merge: false,
            },
            commercialInfo: {
              merge: false,
            } /* ,
            brochure: {
              merge: false,
            },
            media: {
              merge: false,
            }, */,
          },
        },
      },
    }),
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, NotificationsService],
    },
  ],
})
export class GraphQLModule {}
