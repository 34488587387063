import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { SlideUpToggleAnimation } from './util/bottomsheet-animation';
import { AngularBottomSheetConfig } from './util/bottomsheet-config.interface';

@Component({
  selector: 'simplyhaus-bottomsheet',
  templateUrl: './bottomsheet.component.html',
  styleUrls: ['./bottomsheet.component.scss'],
  animations: [SlideUpToggleAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomsheetComponent implements OnInit {
  flags: any = {
    isBottomSheetEnabled: false,
  };
  @Input() options!: AngularBottomSheetConfig;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.flags.isCloseButtonEnabled = this.options.enableCloseButton ? true : false;
    this.options.closeButtonTitle = this.options.closeButtonTitle
      ? this.options.closeButtonTitle
      : 'Cerrar';
  }

  /**
   * Opens bottom sheet component
   */
  open() {
    this.flags.isBottomSheetEnabled = true;
    this.changeDetector.detectChanges();
  }

  /**
   * Closes bottom sheet component
   */
  close() {
    this.flags.isBottomSheetEnabled = false;
    this.changeDetector.detectChanges();
  }

  /**
   * Toggles bottom sheet component
   */
  toggle() {
    this.flags.isBottomSheetEnabled = !this.flags.isBottomSheetEnabled;
    this.changeDetector.detectChanges();
  }

  /**
   * Toggles close button
   */
  toggleCloseButton() {
    this.flags.isCloseButtonEnabled = !this.flags.isCloseButtonEnabled;
    this.changeDetector.detectChanges();
  }

  /**
   * Toggles dark theme
   */
  toggleDarkTheme() {
    this.options.darkTheme = !this.options.darkTheme;
    this.changeDetector.detectChanges();
  }
}
