import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandsPipe',
})
export class ThousandsPipe implements PipeTransform {
  public transform(value: any) {
    if (typeof value === 'number' && value.toString().length > 4) {
      return '$'.concat(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
    }
    return value;
  }
}
