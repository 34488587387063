import {
  Directive,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  ElementRef,
  Input,
  EventEmitter,
  AfterViewInit,
  Renderer2,
  NgZone,
} from '@angular/core';
import { CoreAnimationDirective } from './core-animation.directive';
import gsap from 'gsap';
@Directive({
  selector: '[fadeIn]',
})
export class FadeInAnimationDirective
  extends CoreAnimationDirective
  implements AfterViewInit, OnDestroy
{
  constructor(
    protected element: ElementRef,
    protected renderer: Renderer2,
    private ngZone: NgZone
  ) {
    super(element, renderer);
  }

  ngAfterViewInit() {
    // perform animation
    this.renderer.setStyle(this.element.nativeElement, 'opacity', '0');
    this.animateIn();
  }
  ngOnDestroy(): void {
    this.timeline.reverse();
  }

  protected animateIn() {
    this.ngZone.runOutsideAngular(() => {
      gsap.fromTo(
        this.element.nativeElement,
        { y: '-5%', scale: 0.9, autoAlpha: 0, display: 'none' },
        {
          y: 0,
          scale: 1,
          autoAlpha: 1,
          display: 'block',
          ease: 'back.out',
          duration: this.duration,
          delay: this.delay + this.staggerDelay * this.position,
        }
      );
    });
    super.animateIn();
  }
}
