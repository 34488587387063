import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccessToken, ActivateUserInput, CreatePasswordGQL, LoginGQL, User } from '@gql/graphql';
import { LocalStorageService } from '@app/shared/services/local-storage/local-storage.service';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { UserLogin } from './models/user-login';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  userRole?: string;

  private accessTokenSubject = new BehaviorSubject<AccessToken | null>(null);
  private currentUserSubject = new BehaviorSubject<User | null>(null);
  public accessToken: Observable<AccessToken | null> = this.accessTokenSubject.asObservable();
  public currentUser$ = this.currentUserSubject.asObservable();
  constructor(
    private router: Router,
    private loginGQL: LoginGQL,
    private createPasswordGQL: CreatePasswordGQL,
    private localStorage: LocalStorageService,
    private http: HttpClient
  ) {
    this.loggedIn.next(this.localStorage.getCurrentAccessToken() !== undefined);
  }

  login(payload: UserLogin) {
    return this.loginGQL.mutate({ payload }).pipe(
      map(result => result.data?.login as AccessToken),
      tap(at => {
        this.accessTokenSubject.next(at);
        this.loggedIn.next(true);
        this.localStorage.setCurrentAccessToken(at);
      }),
      tap(accessToken => this.getCurrentUser())
    );
  }

  createPassword(payload: ActivateUserInput) {
    return this.createPasswordGQL
      .mutate({ payload })
      .pipe(map(result => result.data?.createPassword as User));
  }

  logout() {
    this.loggedIn.next(false);

    this.localStorage.clearAccessToken();
    this.accessTokenSubject.next(null);
    this.router.navigate(['/auth/login']);
  }

  getCurrentAccessToken() {
    return this.localStorage.getCurrentAccessToken(); //return this.accessTokenSubject.value;
  }

  getCurrentUser() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this.getCurrentAccessToken().access_token}`);
    return this.http
      .get<User>(`${environment.baseUrl}api/auth/me`, {
        headers: headers,
      })
      .pipe(
        take(1),
        tap(me => {
          console.log('ME:', me);
          this.currentUserSubject.next(me);
        })
      );
  }

  saveCurrentUser(user) {
    let accessToken = this.localStorage.getCurrentAccessToken();
    accessToken.user = { ...user };
    this.localStorage.setCurrentAccessToken(accessToken);
  }

  getCompanyLogo() {
    return this.getCurrentAccessToken().company.logo?.url; //return this.accessTokenSubject.value;
  }

  getRoles() {
    return this.getCurrentAccessToken()?.user.roles;
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable().pipe(filter(b => b !== null));
  }
}
