import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@auth/services/auth.service';
import { SideNavigationService } from '@core/services/side-navigation.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'simplyhaus-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent implements OnInit {
  menu = [
    {
      sectionName: 'Menu',
      items: [
        /* {
          label: 'Dashboard',
          icon: 'chart-bar',
          url: '/dashboard',
        },
        {
          label: 'Hosters',
          icon: 'user-plus',
          url: '/hosters',
        },
        {
          label: 'Actividades',
          icon: 'key',
          url: '/blackbox/project/list',
        },
        {
          label: 'Capacitaciones',
          icon: 'puzzle-piece',
          url: '/blackbox/model/list',
        }, 
        {
          label: 'Propiedades',
          icon: 'building',
          url: '/blackbox/unit/list',
        } 
        {
          label: 'Pagos',
          icon: 'money-bill-wave',
          url: '/blackbox/productcategory/list',
        }, */ {
          icon: 'chart-bar',
          label: 'Analizar propiedad',
          url: '/rentalizer/wizard/form/lead',
        },
        {
          label: 'Prospectos',
          icon: 'users',
          url: '/quotations',
        },
      ],
    },
    {
      sectionName: 'Configuración',
      items: [
        {
          icon: 'user-edit',
          label: 'Perfil',
          url: '/profile',
        },
      ],
    },
  ];
  constructor(
    public sideNavigationService: SideNavigationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
  }
}
