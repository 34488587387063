import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { CloudFile } from 'projects/simplyhaus-app/src/gql/graphql';
import { Observable, throwError } from 'rxjs';
import { finalize, first, map, mergeMap, take, switchMap, catchError } from 'rxjs/operators';
import { DocumentsService } from './documents.service';
import { DocumentStorage } from './models/document.model';
import { FileUpload } from './models/file-upload.model';
import { environment } from 'projects/simplyhaus-app/src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class FileStorageService {
  constructor(private documentsService: DocumentsService, private http: HttpClient) {}

  public uploadFile(formData: FormData): Observable<any> {
    return this.http
      .post<CloudFile>(`${environment.baseUrl}api/files/upload`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(this.errorMgmt));
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  /*   public getFileReference(filename: string) {
    return this.storage.ref(filename);
  } */
}
