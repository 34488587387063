import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { getTranslationDistance } from '../../utils/utils';

@Component({
  selector: 'simplyhaus-grid-item',
  templateUrl: './grid-item.component.html',
  styleUrls: ['./grid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridItemComponent implements OnInit {
  @Input() image: string = '';
  translation = { x: 0, y: 0 };

  constructor() {}

  ngOnInit() {}

  onMouseMove(event: MouseEvent) {
    const distance = getTranslationDistance(
      event.target as HTMLElement,
      event.currentTarget as HTMLElement
    );
    this.translation = distance;
  }

  onMouseLeave(event: MouseEvent) {
    this.translation = { x: 0, y: 0 };
  }
}
