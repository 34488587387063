import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};



export type AccessToken = {
  __typename?: 'AccessToken';
  access_token: Scalars['String'];
  user: User;
  company: Company;
};

export type ActivateUserInput = {
  verificationToken: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export type AddAdminInput = {
  admin?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type AddCollaboratorInput = {
  collaborator?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  _id: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  optionalAddress?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  location: Location;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

/**
 * {
 *        "modelName":"Clientes",
 *        "description":"Listado de clientes",
 *        "endpoint":"clients"
 *    }
 */
export type Client = {
  __typename?: 'Client';
  _id: Scalars['ID'];
  /**
   * {
   *       "fieldName":"Nombre",
   *       "placeholder":"Nombre del cliente",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  name: Scalars['String'];
  /**
   * {
   *       "fieldName":"RUT",
   *       "placeholder":"Rut del cliente",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  rut: Scalars['String'];
  /**
   * {
   *       "fieldName":"Email",
   *       "placeholder":"Correo del cliente",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  email: Scalars['String'];
  /**
   * {
   *       "fieldName":"Teléfono",
   *       "placeholder":"Teléfono del cliente",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  phone: Scalars['String'];
  /**
   * {
   *       "fieldName":"Dirección",
   *       "placeholder":"Dirección del cliente",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  address: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type CloudFile = {
  __typename?: 'CloudFile';
  _id: Scalars['ID'];
  url: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  key: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type Company = ICompany & {
  __typename?: 'Company';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<CloudFile>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime: Scalars['Boolean'];
  kind: Scalars['String'];
  admin?: Maybe<User>;
  collaborators?: Maybe<Array<User>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type CreateAddressInput = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  optionalAddress?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  location?: Maybe<CreateLocationInput>;
  ecommerce?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/**
 * {
 *     "inputName":"Cliente",
 *     "description":"Crea un nuevo cliente",
 *     "endpoint":"clients"
 *  }
 */
export type CreateClientInput = {
  /**
   * {
   *       "fieldName":"Nombre",
   *       "placeholder":"Nombre del cliente",
   *       "onErrorMessage":"Debes ingresar un nombre",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  name?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"RUT",
   *       "placeholder":"RUT del cliente",
   *       "onErrorMessage":"Debes ingresar un RUT válido",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  rut?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Email",
   *       "placeholder":"Correo electrónico",
   *       "onErrorMessage":"Debes ingresar un correo válido",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  email?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Teléfono",
   *       "placeholder":"Teléfono del cliente",
   *       "onErrorMessage":"Debes ingresar un teléfono",
   *       "colspan":1,
   *       "type":"phone",
   *       "show":"true"
   *    }
   */
  phone?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Dirección",
   *       "placeholder":"Dirección del cliente",
   *       "onErrorMessage":"Debes ingresar una dirección",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  address?: Maybe<Scalars['String']>;
  /**
   * {
   *           "fieldName":"Cotizaciones",
   *           "placeholder":"Cotizaciones del cliente",
   *           "onErrorMessage":"No hay error aquí.",
   *           "colspan":1,
   *           "show":"false",
   *           "type":"ref-array",
   *           "endpoint":"quotes",
   *           "extra":"client.name"
   *        }
   */
  quotes?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateCloudFileInput = {
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateCompanyInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
};

export type CreateLayoutConfigInput = {
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/**
 * {
 *     "inputName":"Prospecto",
 *     "description":"Nuevo prospecto",
 *     "endpoint":"leads"
 *  }
 */
export type CreateLeadInput = {
  /**
   * {
   *       "fieldName":"Nombre",
   *       "placeholder":"Ingresa tu nombre",
   *       "onErrorMessage":"Debes ingresar un nombre",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  name?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Apellido",
   *       "placeholder":"Ingresa tu apellido",
   *       "onErrorMessage":"Debes ingresar un apellido",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  lastname?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Comuna de la propiedad",
   *       "placeholder":"Ingresa la comuna de tu propiedad",
   *       "onErrorMessage":"Debes ingresar una comuna",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  city?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Email",
   *       "placeholder":"Ingresa tu email",
   *       "onErrorMessage":"Debes ingresar un email",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  email?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Teléfono",
   *       "placeholder":"Ingresa tu teléfono",
   *       "onErrorMessage":"Debes ingresar un teléfono",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"phone",
   *       "show":true
   *    }
   */
  phone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CreateLocationInput = {
  type: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
};

export type CreateQuotationInput = {
  lead?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['Float']>;
  utilities?: Maybe<Scalars['Float']>;
  hoa_fees?: Maybe<Scalars['Float']>;
  statistics?: Maybe<Scalars['JSONObject']>;
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type CreateRealEstateCompanyInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
};

export type CreateServicesCompanyInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
};

export type CreateUserInput = {
  name: Scalars['String'];
  rut: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};


export type ICompany = {
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<CloudFile>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime: Scalars['Boolean'];
  kind: Scalars['String'];
  admin?: Maybe<User>;
  collaborators?: Maybe<Array<User>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};


export type LayoutConfig = {
  __typename?: 'LayoutConfig';
  _id: Scalars['ID'];
  model: Scalars['String'];
  type: Scalars['String'];
  config?: Maybe<Scalars['JSONObject']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

/**
 * {
 *        "modelName":"Prospectos",
 *        "description":"Listado de prospectos",
 *        "endpoint":"leads"
 *    }
 */
export type Lead = {
  __typename?: 'Lead';
  _id: Scalars['ID'];
  /**
   * {
   *       "fieldName":"Nombre",
   *       "placeholder":"Nombre",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  name: Scalars['String'];
  /**
   * {
   *       "fieldName":"Apellido",
   *       "placeholder":"Apellido",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  lastname: Scalars['String'];
  /**
   * {
   *       "fieldName":"Email",
   *       "placeholder":"Email",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  email: Scalars['String'];
  /**
   * {
   *       "fieldName":"Comuna",
   *       "placeholder":"Comuna",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  city: Scalars['String'];
  /**
   * {
   *       "fieldName":"Teléfono",
   *       "placeholder":"Teléfono",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  phone: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type ListAddressesInput = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  optionalAddress?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  location?: Maybe<CreateLocationInput>;
  ecommerce?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  _id?: Maybe<Scalars['String']>;
};

export type ListClientsInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  quotes?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ListCloudFilesInput = {
  _id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ListCompaniesInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ListLayoutConfigsInput = {
  _id?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSONObject']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ListLeadsInput = {
  /**
   * {
   *       "fieldName":"Nombre",
   *       "placeholder":"Ingresa tu nombre",
   *       "onErrorMessage":"Debes ingresar un nombre",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  name?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Apellido",
   *       "placeholder":"Ingresa tu apellido",
   *       "onErrorMessage":"Debes ingresar un apellido",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  lastname?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Comuna de la propiedad",
   *       "placeholder":"Ingresa la comuna de tu propiedad",
   *       "onErrorMessage":"Debes ingresar una comuna",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  city?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Email",
   *       "placeholder":"Ingresa tu email",
   *       "onErrorMessage":"Debes ingresar un email",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  email?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Teléfono",
   *       "placeholder":"Ingresa tu teléfono",
   *       "onErrorMessage":"Debes ingresar un teléfono",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"phone",
   *       "show":true
   *    }
   */
  phone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['String']>;
};

export type ListQuotationsInput = {
  lead?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['Float']>;
  utilities?: Maybe<Scalars['Float']>;
  hoa_fees?: Maybe<Scalars['Float']>;
  statistics?: Maybe<Scalars['JSONObject']>;
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id?: Maybe<Scalars['String']>;
};

export type ListRealEstateCompaniesInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ListServicesCompaniesInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type ListUserInput = {
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  roles?: Maybe<Array<Scalars['String']>>;
  avatar?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type Location = {
  __typename?: 'Location';
  type: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  updateUser: User;
  deleteUser: User;
  createCompany: Company;
  updateCompany: Company;
  addAdmin: Company;
  addCollaborator: Company;
  removeCollaborator: Company;
  deleteCompany: Company;
  createRealEstateCompany: RealEstateCompany;
  updateRealEstateCompany: RealEstateCompany;
  deleteRealEstateCompany: RealEstateCompany;
  createServicesCompany: ServicesCompany;
  updateServicesCompany: ServicesCompany;
  deleteServicesCompany: ServicesCompany;
  createFile: CloudFile;
  updateFile: CloudFile;
  deleteFile: CloudFile;
  createClient: Client;
  updateClient: Client;
  deleteClient: Client;
  createAddress: Address;
  updateAddress: Address;
  deleteAddress: Address;
  login: AccessToken;
  createPassword: User;
  createLead: Lead;
  updateLead: Lead;
  deleteLead: Lead;
  createLayoutConfig: LayoutConfig;
  updateLayoutConfig: LayoutConfig;
  deleteLayoutConfig: LayoutConfig;
  createQuotation: Quotation;
  updateQuotation: Quotation;
  deleteQuotation: Quotation;
};


export type MutationCreateUserArgs = {
  payload: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  payload: UpdateUserInput;
};


export type MutationDeleteUserArgs = {
  _id: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  payload: CreateCompanyInput;
};


export type MutationUpdateCompanyArgs = {
  payload: UpdateCompanyInput;
};


export type MutationAddAdminArgs = {
  payload: AddAdminInput;
};


export type MutationAddCollaboratorArgs = {
  payload: AddCollaboratorInput;
};


export type MutationRemoveCollaboratorArgs = {
  payload: RemoveCollaboratorInput;
};


export type MutationDeleteCompanyArgs = {
  _id: Scalars['String'];
};


export type MutationCreateRealEstateCompanyArgs = {
  payload: CreateRealEstateCompanyInput;
};


export type MutationUpdateRealEstateCompanyArgs = {
  payload: UpdateRealEstateCompanyInput;
};


export type MutationDeleteRealEstateCompanyArgs = {
  _id: Scalars['String'];
};


export type MutationCreateServicesCompanyArgs = {
  payload: CreateServicesCompanyInput;
};


export type MutationUpdateServicesCompanyArgs = {
  payload: UpdateServicesCompanyInput;
};


export type MutationDeleteServicesCompanyArgs = {
  _id: Scalars['String'];
};


export type MutationCreateFileArgs = {
  payload: CreateCloudFileInput;
};


export type MutationUpdateFileArgs = {
  payload: UpdateCloudFileInput;
};


export type MutationDeleteFileArgs = {
  _id: Scalars['String'];
};


export type MutationCreateClientArgs = {
  payload: CreateClientInput;
};


export type MutationUpdateClientArgs = {
  payload: UpdateClientInput;
};


export type MutationDeleteClientArgs = {
  _id: Scalars['String'];
};


export type MutationCreateAddressArgs = {
  payload: CreateAddressInput;
};


export type MutationUpdateAddressArgs = {
  payload: UpdateAddressInput;
};


export type MutationDeleteAddressArgs = {
  _id: Scalars['String'];
};


export type MutationLoginArgs = {
  payload: LoginInput;
};


export type MutationCreatePasswordArgs = {
  payload: ActivateUserInput;
};


export type MutationCreateLeadArgs = {
  payload: CreateLeadInput;
};


export type MutationUpdateLeadArgs = {
  payload: UpdateLeadInput;
};


export type MutationDeleteLeadArgs = {
  _id: Scalars['String'];
};


export type MutationCreateLayoutConfigArgs = {
  payload: CreateLayoutConfigInput;
};


export type MutationUpdateLayoutConfigArgs = {
  payload: UpdateLayoutConfigInput;
};


export type MutationDeleteLayoutConfigArgs = {
  _id: Scalars['String'];
};


export type MutationCreateQuotationArgs = {
  payload: CreateQuotationInput;
};


export type MutationUpdateQuotationArgs = {
  payload: UpdateQuotationInput;
};


export type MutationDeleteQuotationArgs = {
  _id: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  hello: Scalars['String'];
  user: User;
  users: Array<User>;
  company: Company;
  companies: Array<Company>;
  realEstate: RealEstateCompany;
  realEstates: Array<RealEstateCompany>;
  service: ServicesCompany;
  services: Array<ServicesCompany>;
  file: CloudFile;
  files: Array<CloudFile>;
  client: Client;
  clients: Array<Client>;
  address: Address;
  addresses: Array<Address>;
  lead: Lead;
  leads: Array<Lead>;
  layoutConfig: LayoutConfig;
  layoutConfigs: Array<LayoutConfig>;
  quotation: Quotation;
  quotations: Array<Quotation>;
};


export type QueryUserArgs = {
  _id: Scalars['String'];
};


export type QueryUsersArgs = {
  filters?: Maybe<ListUserInput>;
};


export type QueryCompanyArgs = {
  _id: Scalars['String'];
};


export type QueryCompaniesArgs = {
  filters?: Maybe<ListCompaniesInput>;
};


export type QueryRealEstateArgs = {
  _id: Scalars['String'];
};


export type QueryRealEstatesArgs = {
  filters?: Maybe<ListRealEstateCompaniesInput>;
};


export type QueryServiceArgs = {
  _id: Scalars['String'];
};


export type QueryServicesArgs = {
  filters?: Maybe<ListServicesCompaniesInput>;
};


export type QueryFileArgs = {
  _id: Scalars['String'];
};


export type QueryFilesArgs = {
  filters?: Maybe<ListCloudFilesInput>;
};


export type QueryClientArgs = {
  _id: Scalars['String'];
};


export type QueryClientsArgs = {
  filters?: Maybe<ListClientsInput>;
};


export type QueryAddressArgs = {
  _id: Scalars['String'];
};


export type QueryAddressesArgs = {
  filters?: Maybe<ListAddressesInput>;
};


export type QueryLeadArgs = {
  _id: Scalars['String'];
};


export type QueryLeadsArgs = {
  filters?: Maybe<ListLeadsInput>;
};


export type QueryLayoutConfigArgs = {
  _id: Scalars['String'];
};


export type QueryLayoutConfigsArgs = {
  filters?: Maybe<ListLayoutConfigsInput>;
};


export type QueryQuotationArgs = {
  _id: Scalars['String'];
};


export type QueryQuotationsArgs = {
  filters?: Maybe<ListQuotationsInput>;
};

export type Quotation = {
  __typename?: 'Quotation';
  _id: Scalars['ID'];
  lead: Lead;
  purchase_price: Scalars['Float'];
  utilities: Scalars['Float'];
  hoa_fees: Scalars['Float'];
  statistics?: Maybe<Scalars['JSONObject']>;
  address?: Maybe<Address>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type RealEstateCompany = ICompany & {
  __typename?: 'RealEstateCompany';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<CloudFile>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime: Scalars['Boolean'];
  kind: Scalars['String'];
  admin?: Maybe<User>;
  collaborators?: Maybe<Array<User>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type RemoveCollaboratorInput = {
  collaborator?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
};

export type ServicesCompany = ICompany & {
  __typename?: 'ServicesCompany';
  _id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<CloudFile>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime: Scalars['Boolean'];
  kind: Scalars['String'];
  admin?: Maybe<User>;
  collaborators?: Maybe<Array<User>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type UpdateAddressInput = {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  optionalAddress?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  location?: Maybe<CreateLocationInput>;
  ecommerce?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  _id: Scalars['String'];
};

/**
 * {
 *        "inputName":"Cliente",
 *        "description":"Actualizar cliente",
 *        "endpoint":"clients"
 *    }
 */
export type UpdateClientInput = {
  _id: Scalars['String'];
  /**
   * {
   *       "fieldName":"Nombre",
   *       "placeholder":"Nombre del cliente",
   *       "onErrorMessage":"Debes ingresar un nombre",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  name?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"RUT",
   *       "placeholder":"RUT del cliente",
   *       "onErrorMessage":"Debes ingresar un RUT válido",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  rut?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Email",
   *       "placeholder":"Correo electrónico",
   *       "onErrorMessage":"Debes ingresar un correo válido",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  email?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Teléfono",
   *       "placeholder":"Teléfono del cliente",
   *       "onErrorMessage":"Debes ingresar un teléfono",
   *       "colspan":1,
   *       "type":"phone",
   *       "show":"true"
   *    }
   */
  phone?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Dirección",
   *       "placeholder":"Dirección del cliente",
   *       "onErrorMessage":"Debes ingresar una dirección",
   *       "colspan":1,
   *       "type":"text",
   *       "show":"true"
   *    }
   */
  address?: Maybe<Scalars['String']>;
  /**
   * {
   *           "fieldName":"Cotizaciones",
   *           "placeholder":"Cotizaciones del cliente",
   *           "onErrorMessage":"No hay error aquí.",
   *           "colspan":1,
   *           "show":"false",
   *           "type":"ref-array",
   *           "endpoint":"quotes",
   *           "extra":"client.name"
   *        }
   */
  quotes?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateCloudFileInput = {
  _id: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateCompanyInput = {
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateLayoutConfigInput = {
  _id: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSONObject']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

/**
 * {
 *       "inputName":"Prospecto",
 *       "description":"Editar prospecto",
 *       "endpoint":"leads"
 *    }
 */
export type UpdateLeadInput = {
  /**
   * {
   *       "fieldName":"Nombre",
   *       "placeholder":"Ingresa tu nombre",
   *       "onErrorMessage":"Debes ingresar un nombre",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  name?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Apellido",
   *       "placeholder":"Ingresa tu apellido",
   *       "onErrorMessage":"Debes ingresar un apellido",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  lastname?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Comuna de la propiedad",
   *       "placeholder":"Ingresa la comuna de tu propiedad",
   *       "onErrorMessage":"Debes ingresar una comuna",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  city?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Email",
   *       "placeholder":"Ingresa tu email",
   *       "onErrorMessage":"Debes ingresar un email",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"text",
   *       "show":true
   *    }
   */
  email?: Maybe<Scalars['String']>;
  /**
   * {
   *       "fieldName":"Teléfono",
   *       "placeholder":"Ingresa tu teléfono",
   *       "onErrorMessage":"Debes ingresar un teléfono",
   *       "grid":{
   *          "cols":6,
   *          "rows":2,
   *          "x":0,
   *          "y":0
   *         },
   *       "type":"phone",
   *       "show":true
   *    }
   */
  phone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['String'];
};

export type UpdateQuotationInput = {
  lead?: Maybe<Scalars['String']>;
  purchase_price?: Maybe<Scalars['Float']>;
  utilities?: Maybe<Scalars['Float']>;
  hoa_fees?: Maybe<Scalars['Float']>;
  statistics?: Maybe<Scalars['JSONObject']>;
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  _id: Scalars['String'];
};

export type UpdateRealEstateCompanyInput = {
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateServicesCompanyInput = {
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  emailBank?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['String']>;
  collaborators?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rut?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  isFirstTime?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  avatar?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  name: Scalars['String'];
  rut: Scalars['String'];
  email: Scalars['String'];
  isFirstTime: Scalars['Boolean'];
  emailVerified: Scalars['Boolean'];
  verificationToken?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  /** Imagen:Imagen de perfil:image */
  avatar?: Maybe<CloudFile>;
  company?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isDeleted: Scalars['Boolean'];
};

export type CompaniesForListQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesForListQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'website' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'CloudFile' }
      & Pick<CloudFile, 'url'>
    )> }
  )> }
);

export type GetCompanyByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCompanyByIdQuery = (
  { __typename?: 'Query' }
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'rut' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { admin?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'rut' | 'email' | 'isFirstTime' | 'emailVerified'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'rut' | 'roles' | 'email' | 'phone' | 'position'>
    )>>, logo?: Maybe<(
      { __typename?: 'CloudFile' }
      & Pick<CloudFile, 'url'>
    )> }
  ) }
);

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'rut' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { admin?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name' | 'rut' | 'email' | 'emailVerified' | 'isFirstTime'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'rut' | 'roles' | 'email' | 'phone' | 'position'>
    )>>, logo?: Maybe<(
      { __typename?: 'CloudFile' }
      & Pick<CloudFile, 'url'>
    )> }
  )> }
);

export type AddAdminMutationVariables = Exact<{
  data: AddAdminInput;
}>;


export type AddAdminMutation = (
  { __typename?: 'Mutation' }
  & { addAdmin: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type AddCollaboratorMutationVariables = Exact<{
  data: AddCollaboratorInput;
}>;


export type AddCollaboratorMutation = (
  { __typename?: 'Mutation' }
  & { addCollaborator: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type RemoveCollaboratorMutationVariables = Exact<{
  data: RemoveCollaboratorInput;
}>;


export type RemoveCollaboratorMutation = (
  { __typename?: 'Mutation' }
  & { removeCollaborator: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type CreateCompanyMutationVariables = Exact<{
  company: CreateCompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'CloudFile' }
      & Pick<CloudFile, 'url'>
    )>, admin?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, '_id'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'roles'>
    )>> }
  ) }
);

export type UpdateCompanyMutationVariables = Exact<{
  company: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: (
    { __typename?: 'Company' }
    & Pick<Company, '_id' | 'name' | 'email' | 'phone' | 'address' | 'website' | 'primaryColor' | 'secondaryColor' | 'bank' | 'accountType' | 'accountNumber' | 'emailBank' | 'isFirstTime' | 'kind' | 'createdAt' | 'updatedAt' | 'isDeleted'>
    & { logo?: Maybe<(
      { __typename?: 'CloudFile' }
      & Pick<CloudFile, 'url'>
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'position'>
    )>> }
  ) }
);

export type CreatePasswordMutationVariables = Exact<{
  payload: ActivateUserInput;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { createPassword: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'email' | 'emailVerified' | 'roles' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type LoginMutationVariables = Exact<{
  payload: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AccessToken' }
    & Pick<AccessToken, 'access_token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, '_id' | 'name' | 'email' | 'rut' | 'phone' | 'isFirstTime' | 'roles'>
    ), company: (
      { __typename?: 'Company' }
      & Pick<Company, '_id' | 'name' | 'isFirstTime'>
    ) }
  ) }
);

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'rut' | 'email' | 'emailVerified' | 'verificationToken' | 'position' | 'phone' | 'roles' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  user: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'rut' | 'email' | 'emailVerified' | 'isFirstTime' | 'verificationToken' | 'position' | 'phone' | 'roles' | 'company' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'name' | 'email' | 'createdAt' | 'updatedAt' | 'isDeleted'>
  ) }
);

export type GetUserProfileQueryVariables = Exact<{
  _id: Scalars['String'];
}>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'email' | 'rut' | 'phone'>
    & { avatar?: Maybe<(
      { __typename?: 'CloudFile' }
      & Pick<CloudFile, '_id' | 'url'>
    )> }
  ) }
);

export type GetQuotationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuotationsQuery = (
  { __typename?: 'Query' }
  & { quotations: Array<(
    { __typename?: 'Quotation' }
    & Pick<Quotation, '_id' | 'purchase_price' | 'utilities' | 'hoa_fees' | 'statistics'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, '_id' | 'name' | 'lastname' | 'email' | 'city' | 'phone' | 'createdAt'>
    ), address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'streetName' | 'streetNumber' | 'optionalAddress' | 'address' | 'country'>
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'coordinates'>
      ) }
    )> }
  )> }
);

export type GetQuotationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetQuotationQuery = (
  { __typename?: 'Query' }
  & { quotation: (
    { __typename?: 'Quotation' }
    & Pick<Quotation, '_id' | 'purchase_price' | 'utilities' | 'hoa_fees' | 'statistics'>
    & { lead: (
      { __typename?: 'Lead' }
      & Pick<Lead, '_id' | 'name' | 'lastname' | 'email' | 'city' | 'phone' | 'createdAt'>
    ), address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'streetName' | 'streetNumber' | 'optionalAddress' | 'address' | 'country'>
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'coordinates'>
      ) }
    )> }
  ) }
);

export const CompaniesForListDocument = gql`
    query companiesForList {
  companies(filters: {}) {
    _id
    name
    website
    logo {
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompaniesForListGQL extends Apollo.Query<CompaniesForListQuery, CompaniesForListQueryVariables> {
    document = CompaniesForListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCompanyByIdDocument = gql`
    query getCompanyById($id: String!) {
  company(_id: $id) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    isFirstTime
    kind
    rut
    admin {
      name
      rut
      email
      isFirstTime
      emailVerified
    }
    collaborators {
      _id
      name
      rut
      roles
      email
      phone
      position
    }
    logo {
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompanyByIdGQL extends Apollo.Query<GetCompanyByIdQuery, GetCompanyByIdQueryVariables> {
    document = GetCompanyByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCompaniesDocument = gql`
    query getCompanies {
  companies {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    isFirstTime
    kind
    rut
    admin {
      name
      rut
      email
      emailVerified
      isFirstTime
    }
    collaborators {
      _id
      name
      rut
      roles
      email
      phone
      position
    }
    logo {
      url
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompaniesGQL extends Apollo.Query<GetCompaniesQuery, GetCompaniesQueryVariables> {
    document = GetCompaniesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddAdminDocument = gql`
    mutation addAdmin($data: AddAdminInput!) {
  addAdmin(payload: $data) {
    _id
    name
    email
    isFirstTime
    kind
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddAdminGQL extends Apollo.Mutation<AddAdminMutation, AddAdminMutationVariables> {
    document = AddAdminDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCollaboratorDocument = gql`
    mutation addCollaborator($data: AddCollaboratorInput!) {
  addCollaborator(payload: $data) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    isFirstTime
    kind
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCollaboratorGQL extends Apollo.Mutation<AddCollaboratorMutation, AddCollaboratorMutationVariables> {
    document = AddCollaboratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveCollaboratorDocument = gql`
    mutation removeCollaborator($data: RemoveCollaboratorInput!) {
  removeCollaborator(payload: $data) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    isFirstTime
    kind
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveCollaboratorGQL extends Apollo.Mutation<RemoveCollaboratorMutation, RemoveCollaboratorMutationVariables> {
    document = RemoveCollaboratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCompanyDocument = gql`
    mutation createCompany($company: CreateCompanyInput!) {
  createCompany(payload: $company) {
    _id
    name
    email
    phone
    address
    website
    logo {
      url
    }
    admin {
      _id
    }
    primaryColor
    secondaryColor
    isFirstTime
    kind
    collaborators {
      _id
      name
      roles
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCompanyGQL extends Apollo.Mutation<CreateCompanyMutation, CreateCompanyMutationVariables> {
    document = CreateCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCompanyDocument = gql`
    mutation updateCompany($company: UpdateCompanyInput!) {
  updateCompany(payload: $company) {
    _id
    name
    email
    phone
    address
    website
    primaryColor
    secondaryColor
    bank
    accountType
    accountNumber
    emailBank
    isFirstTime
    kind
    logo {
      url
    }
    collaborators {
      _id
      name
      position
    }
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanyGQL extends Apollo.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {
    document = UpdateCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePasswordDocument = gql`
    mutation createPassword($payload: ActivateUserInput!) {
  createPassword(payload: $payload) {
    _id
    name
    email
    emailVerified
    roles
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePasswordGQL extends Apollo.Mutation<CreatePasswordMutation, CreatePasswordMutationVariables> {
    document = CreatePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($payload: LoginInput!) {
  login(payload: $payload) {
    access_token
    user {
      _id
      name
      email
      rut
      phone
      isFirstTime
      roles
    }
    company {
      _id
      name
      isFirstTime
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($user: CreateUserInput!) {
  createUser(payload: $user) {
    _id
    name
    rut
    email
    emailVerified
    verificationToken
    position
    phone
    roles
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($user: UpdateUserInput!) {
  updateUser(payload: $user) {
    _id
    name
    rut
    email
    emailVerified
    isFirstTime
    verificationToken
    position
    phone
    roles
    company
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: String!) {
  deleteUser(_id: $userId) {
    _id
    name
    email
    createdAt
    updatedAt
    isDeleted
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserProfileDocument = gql`
    query getUserProfile($_id: String!) {
  user(_id: $_id) {
    name
    email
    avatar {
      _id
      url
    }
    rut
    phone
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserProfileGQL extends Apollo.Query<GetUserProfileQuery, GetUserProfileQueryVariables> {
    document = GetUserProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetQuotationsDocument = gql`
    query getQuotations {
  quotations {
    _id
    lead {
      _id
      name
      lastname
      email
      city
      phone
      createdAt
    }
    purchase_price
    utilities
    hoa_fees
    statistics
    address {
      streetName
      streetNumber
      optionalAddress
      address
      country
      location {
        coordinates
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuotationsGQL extends Apollo.Query<GetQuotationsQuery, GetQuotationsQueryVariables> {
    document = GetQuotationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetQuotationDocument = gql`
    query getQuotation($id: String!) {
  quotation(_id: $id) {
    _id
    lead {
      _id
      name
      lastname
      email
      city
      phone
      createdAt
    }
    purchase_price
    utilities
    hoa_fees
    statistics
    address {
      streetName
      streetNumber
      optionalAddress
      address
      country
      location {
        coordinates
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetQuotationGQL extends Apollo.Query<GetQuotationQuery, GetQuotationQueryVariables> {
    document = GetQuotationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }