import { environment } from 'projects/simplyhaus-app/src/environments/environment';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormArray, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationsService } from 'projects/simplyhaus-app/src/app/core/services/notifications.service';
import { CloudFile } from 'projects/simplyhaus-app/src/gql/graphql';
import { FileStorageService } from 'projects/simplyhaus-app/src/app/shared/services/documents/firebase-storage.service';
import { TuiPdfViewerService } from '@taiga-ui/kit';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'simplyhaus-single-image',
  templateUrl: './single-image.component.html',
  styleUrls: ['./single-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SingleImageComponent),
    },
  ],
})
export class SingleImageComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  file?: CloudFile | null;

  onChange = (value: CloudFile) => {};

  @Output() fileChange = new EventEmitter<CloudFile>();

  fileList: NzUploadFile[] = [];
  previewImage?: string;
  previewVisible = false;
  loading = false;

  @Output() onFileUpload = new EventEmitter<NzUploadFile[]>();

  ACTION_URL: string = `${environment.baseUrl}api/files/upload`;
  constructor(
    private uploadService: FileStorageService,
    private ref: ChangeDetectorRef,
    private notifications: NotificationsService,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
    @Inject(TuiPdfViewerService) private readonly pdfService: TuiPdfViewerService
  ) {}

  writeValue(): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(): void {}

  ngOnInit() {
    if (this.file) {
      this.fileList = [
        {
          uid: this.file._id,
          name: this.file.url,
          status: 'done',
          url: this.file.url,
          thumbUrl: this.file.url,
          response: this.file,
        },
      ];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let file = changes['file'];

    if (!file.firstChange) {
      this.fileList = [
        {
          uid: file.currentValue._id,
          name: file.currentValue.url,
          status: 'done',
          url: file.currentValue.url,
          thumbUrl: file.currentValue.url,
          response: file.currentValue,
        },
      ];
      this.ref.detectChanges();
      console.log('CURRENT FILES', this.fileList);
    }
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    console.log('handling preview', file);
    if (!file.url && !file.preview && !file.response.url) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview || file.response.url;
    this.previewVisible = true;
  };

  handlePreviewFile = (file: NzUploadFile): Observable<string> =>
    new Observable((observer: Observer<string>) => {
      if (!file.response) {
        observer.complete();
        return;
      }
      this.previewImage = file.response.url;
      observer.next(file.response.url);
      observer.complete();
    });

  handleChange(info: { file: NzUploadFile; fileList }): void {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    this.fileList = fileList;
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.notifications.success('Archivo subido correctamente.');
        getBase64(info.file!.originFileObj!).then(img => {
          this.previewImage = img || info.file.response.url;
          this.loading = false;
        });
        this.fileChange.emit(info.file.response);
        this.onFileUpload.emit(this.fileList);
        this.ref.detectChanges();
        break;
      case 'error':
        this.notifications.error('Error en la subida del archivo. Reintente.');
        this.loading = false;
        break;
      case 'removed':
        this.notifications.success('Archivo eliminado correctamente.');
        console.log(info);
        break;
    }
  }

  handleUpload = (item: any) => {
    const formData = new FormData();
    formData.append(item.name, item.file as any);
    return this.uploadService.uploadFile(formData).subscribe(
      res => {
        item.onSuccess(res);
        console.log('success', res);
        this.file = res;
        this.fileChange.emit(res);
      },
      err => {
        item.onError(err, item.file);
      }
    );
  };
}
const getBase64 = (file: File): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const getBase64FromUrl = async (url): Promise<any> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};
