import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SideNavigationService } from 'projects/simplyhaus-app/src/app/core/services/side-navigation.service';

@Component({
  selector: 'simplyhaus-side-navigation-item',
  templateUrl: './side-navigation-item.component.html',
  styleUrls: ['./side-navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationItemComponent implements OnInit {
  @Input()
  item!: any;

  constructor(public sidebar: SideNavigationService) {}

  ngOnInit(): void {}
}
