<header
  *ngIf="sidebar.showSidebar | async"
  class="flex w-full justify-end items-center bg-gradient-to-r bg-black p-3"
>
  <!-- Profile menu -->
  <div class="relative mx-4">
    <div class="align-middle rounded-full focus:shadow-outline-purple focus:outline-none">
      <div class="flex" *ngIf="me$ | async as me">
        <img
          class="object-cover w-12 h-12 rounded-full"
          [src]="me.avatar?.url"
          alt=""
          aria-hidden="true"
        />
        <!-- <span
        aria-hidden="true"
        class="absolute top-[2.5rem] ml-8 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-green-500 border-2 border-white rounded-full dark:border-gray-800"
      ></span> -->
        <div class="relative">
          <div class="flex">
            <span
              class="ml-3 text-[#292D32] font-gilmer font-semibold text-[14px] leading-[18-7px]"
              >{{ me.name }}</span
            >
          </div>
          <div class="flex justify-between">
            <span class="justify-start ml-3 font-gilmer text-[14px] text-[#808080]">{{
              me.roles | headerRolesPipe
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex items-center text-white text-base">
    <!--     <simplyhaus-button
      (click)="showNotifications()"
      type="header"
      title="Notificaciones"
      badge="2"
      badgeColor="red"
      color="gray"
      icon="bell"
    ></simplyhaus-button> -->
    <simplyhaus-button
      type="header"
      (click)="logout()"
      title="Cerrar sesión"
      icon="sign-out-alt"
      color="white"
    ></simplyhaus-button>
  </div>
</header>
