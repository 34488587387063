<input
  class="bg-{{ color }}-600 border-{{
    color
  }}-600 text-slate-50 hover:scale-95 transform-gpu border hover:border-{{
    color
  }}-500 border hover:text-gray-700 border-opacity-0 hover:border-opacity-100 text-sm mx-2 py-3 px-4 ease-out rounded-lg hover:bg-slate-50 hover:text-{{
    color
  }}-500 hover:shadow-lg transition-all duration-300 cursor-pointer"
  [type]="type"
/>
