import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './features/auth/guards/auth.guard';

const routes: Routes = [
  //{ path: '', redirectTo: '/broker/dashboard', pathMatch: 'full' },
  {
    path: '',
    redirectTo: 'rentalizer/wizard/form/lead',
    pathMatch: 'full',
  },
  {
    path: 'admin' /* 
    canActivate: [AuthGuard], */,
    loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'blackbox',
    loadChildren: () => import('./features/blackbox/blackbox.module').then(m => m.BlackboxModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'quotations',
    loadChildren: () =>
      import('./features/quotations/quotations.module').then(m => m.QuotationsModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'playground',
    loadChildren: () =>
      import('./features/playground/playground.module').then(m => m.PlaygroundModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'profile',
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'rentalizer',
    loadChildren: () =>
      import('./features/rentalizer/rentalizer.module').then(m => m.RentalizerModule),
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
