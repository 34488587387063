<nz-upload
  class="avatar-uploader w-full h-full object-cover justify-center"
  [nzAction]="ACTION_URL"
  nzName="file"
  [nzListType]="inputType"
  [nzShowUploadList]="false"
  [nzBeforeUpload]="beforeUpload"
  (nzChange)="handleChange($event)"
  [nzPreviewFile]="previewFile"
>
  <ng-container [ngSwitch]="inputType">
    <ng-container *ngSwitchCase="'picture-card'">
      <ng-container>
        <div *ngIf="loading || !avatarUrl" [ngClass]="routeAnimationsElements">
          <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
          <div style="margin-top: 8px">{{ loading ? loadingText : textButton }}</div>
        </div>
      </ng-container>
      <img
        [ngClass]="routeAnimationsElements"
        *ngIf="avatarUrl && !loading"
        [src]="avatarUrl"
        class="w-full h-full object-cover"
      />
    </ng-container>
    <ng-container *ngSwitchCase="'picture'">
      <ng-container>
        <div>
          <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
          <div style="margin-top: 8px">{{ loading ? loadingText : textButton }}</div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'text'">
      <ng-container>
        <div>
          <i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
        </div>
        <div style="margin-top: 8px">{{ loading ? loadingText : textButton }}</div>
      </ng-container>
    </ng-container>
  </ng-container>
</nz-upload>

<!-- <ng-container #viewFile>
  <simplyhaus-button *ngIf="avatarUrl" icon="file" (click)="viewDocument(actions)">
    Ver archivo
  </simplyhaus-button>
</ng-container> -->

<ng-template #actions let-content="content">
  <a tuiButton shape="rounded" size="s" download [href]="content"> Download </a>
</ng-template>
<!-- <div class="d-flex hover:text-black" [ngSwitch]="inputType">
  <div class="d-flex" *ngSwitchCase="'normal'">
    <span class="input--upload">
      <div class="d-flex justify-content-between align-items-center">
        <span class="ellipsis">{{ currentFileName }}</span>
        <div *ngIf="currentFileUpload" class="in-progress">
          <small
            class="in-progress-bar in-progress-bar-info"
            role="progressbar"
            attr.aria-valuenow="{{ percentage }}"
            aria-valuemin="0"
            aria-valuemax="100"
            [ngStyle]="{ width: percentage + '%' }"
          >
            <i *ngIf="percentage < 100" class="ico ico-loader text-azure"></i>
            <i *ngIf="percentage == 100" class="ico ico-check text-tradewind"></i>
          </small>
        </div>
      </div>
      <input
        type="file"
        placeholder="Selecciona un archivo"
        multiple="false"
        id="select-file"
        [ngModel]="currentFileUpload"
        (ngModelChange)="onChange($event)"
        (change)="selectFile($event)"
      />
      <label id="upload-button" for="select-file">
        <button class="btn btn--upload bg-azure" [disabled]="!selectedFiles">
          <i class="ico ico-upload"></i>
        </button>
      </label>
    </span>
  </div>

  <div class="d-flex" *ngSwitchCase="'brochure'" class="hover:text-black">
    <span class="input--upload hidden">
      <div class="d-flex justify-content-between align-items-center">
        <span class="ellipsis">{{ currentFileName }}</span>
        <div *ngIf="currentFileUpload" class="in-progress">
          <small
            class="in-progress-bar in-progress-bar-info"
            role="progressbar"
            attr.aria-valuenow="{{ percentage }}"
            aria-valuemin="0"
            aria-valuemax="100"
            [ngStyle]="{ width: percentage + '%' }"
          >
            <i *ngIf="percentage < 100" class="ico ico-loader text-azure"></i>
            <i *ngIf="percentage == 100" class="ico ico-check text-tradewind"></i>
          </small>
        </div>
      </div>

      <input
        style="display: none"
        type="file"
        placeholder="Selecciona un archivo"
        id="select-file"
        [ngModel]="currentFileUpload"
        (ngModelChange)="onChange($event)"
        (change)="selectFile($event)"
      />
    </span>
    <label
      for="select-file"
      class="bg-blue-500 border-blue-600 text-blue-50 hover:scale-95 transform-gpu border hover:border-blue-500 border hover:color-white border-opacity-0 hover:border-opacity-100 text-sm mx-2 py-3 px-4 ease-out rounded-lg hover:bg-white hover:text-slate-500 hover:shadow-lg transition-all duration-300 cursor-pointer"
    >
      {{ textButton }}
    </label>
  </div>

  <div class="d-flex" *ngSwitchCase="'cover'" class="hover:text-black">
    <span class="input--upload hidden">
      <div class="d-flex justify-content-between align-items-center">
        <span class="ellipsis">{{ currentFileName }}</span>
        <div *ngIf="currentFileUpload" class="in-progress">
          <small
            class="in-progress-bar in-progress-bar-info"
            role="progressbar"
            attr.aria-valuenow="{{ percentage }}"
            aria-valuemin="0"
            aria-valuemax="100"
            [ngStyle]="{ width: percentage + '%' }"
          >
            <i *ngIf="percentage < 100" class="ico ico-loader text-azure"></i>
            <i *ngIf="percentage == 100" class="ico ico-check text-tradewind"></i>
          </small>
        </div>
      </div>

      <input
        style="display: none"
        type="file"
        placeholder="Selecciona un archivo"
        id="select-file"
        [ngModel]="currentFileUpload"
        (ngModelChange)="onChange($event)"
        (change)="selectFile($event)"
      />
    </span>
    <label
      for="select-file"
      class="bg-blue-500 border-blue-600 text-blue-50 hover:scale-95 transform-gpu border hover:border-blue-500 border hover:color-white border-opacity-0 hover:border-opacity-100 text-sm mx-2 py-3 px-4 ease-out rounded-lg hover:bg-white hover:text-slate-500 hover:shadow-lg transition-all duration-300 cursor-pointer"
    >
      {{ textButton }}
    </label>
  </div>

  <div class="d-flex" *ngSwitchCase="'frontCard'" class="hover:text-black">
    <span class="input--upload hidden">
      <div class="d-flex justify-content-between align-items-center">
        <span class="ellipsis">{{ currentFileName }}</span>
        <div *ngIf="currentFileUpload" class="in-progress">
          <small
            class="in-progress-bar in-progress-bar-info"
            role="progressbar"
            attr.aria-valuenow="{{ percentage }}"
            aria-valuemin="0"
            aria-valuemax="100"
            [ngStyle]="{ width: percentage + '%' }"
          >
            <i *ngIf="percentage < 100" class="ico ico-loader text-azure"></i>
            <i *ngIf="percentage == 100" class="ico ico-check text-tradewind"></i>
          </small>
        </div>
      </div>

      <input
        style="display: none"
        type="file"
        placeholder="Selecciona un archivo"
        multiple="false"
        id="select-file"
        [ngModel]="currentFileUpload"
        (ngModelChange)="onChange($event)"
        (change)="selectFile($event)"
      />
    </span>
    <label for="select-file" class="text-azure" style="cursor: pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
        />
      </svg>
    </label>
  </div>
  <ng-container *ngIf="preview && picture">
    <figure class="figure">
      <img [src]="picture" [alt]="currentFileName" />
    </figure>
  </ng-container>
</div>
 -->
