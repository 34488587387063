import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from 'projects/simplyhaus-app/src/app/features/auth/services/auth.service';
import { Subscription } from 'rxjs';
import { Role } from 'projects/simplyhaus-app/src/app/core/enums/role.enum';
import { CustomRole } from './getRole';
@Directive({
  selector: '[accessControl]',
})
export class AccessControlDirective implements AfterViewInit {
  InfoRol: Array<CustomRole> = [];
  accessToken$ = Subscription;
  @Input('companyType') companyType!: string;
  @Input('role') role!: Array<string>;
  dataAllow = new CustomRole();
  constructor(
    private elementRef: ElementRef,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}
  ngAfterViewInit(): void {
    this.dataAllow.role = this.role;
    this.dataAllow.companyType = this.companyType;
    this.InfoRol.push(this.dataAllow);
    this.elementRef.nativeElement.style.display = 'none';
    this.checkAccess();
  }
  checkRole(): boolean {
    let data = false;
    for (let item of this.dataAllow.role) {
      if (this.authService.getCurrentAccessToken().user.roles.includes(item)) {
        data = true;
        break;
      }
    }
    return data;
  }
  checkAccess(): void {
    const currentRoles = this.authService.getCurrentAccessToken().user.roles as string[];
    this.elementRef.nativeElement.style.display =
      currentRoles.includes(this.dataAllow.companyType) && this.checkRole() ? 'block' : 'none';
    /*  if (this.companyType === 'REAL_ESTATE') {
      if (
        currentRoles.includes(this.accessControl) &&
        currentRoles.includes(Role.REAL_ESTATE_COLLABORATOR)
      ) {
        this.elementRef.nativeElement.style.display = 'block';
      }
    } else if (this.companyType === 'BROKER') {
      if (
        currentRoles.includes(this.accessControl) &&
        currentRoles.includes(Role.BROKER_COLLABORATOR)
      ) {
        this.elementRef.nativeElement.style.display = 'block';
      }
    } */
  }
}
