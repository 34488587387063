<div class="clearfix">
  <nz-upload
    nzListType="picture-card"
    [(nzFileList)]="fileList"
    [nzShowButton]="fileList.length < fileLimit"
    [nzPreview]="handlePreview"
    [nzMultiple]="true"
    [nzCustomRequest]="handleUpload"
    (nzChange)="handleChange($event)"
  >
    <div class="ml-2">
      <span nz-icon nzType="plus"></span>
      <div style="margin-top: 8px">{{ title ? title : 'imagen' }}</div>
    </div>
  </nz-upload>
  <nz-modal
    [nzVisible]="previewVisible"
    [nzContent]="modalContent"
    [nzFooter]="null"
    (nzOnCancel)="previewVisible = false"
  >
    <ng-template #modalContent>
      <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
    </ng-template>
  </nz-modal>
</div>
