<div
  [ngSwitch]="icon"
  [ngClass]="{ 'opacity-75': isDefined }"
  class="sm:w-full shadow-md cursor-pointer m-4 md:w-32 md:h-52 py-16 hover:-translate-y-1 text-center hover:shadow hover:scale-105 transition-transform transform-gpu ease-out duration-300"
>
  <div *ngSwitchCase="'document'">
    <img
      *ngIf="!isDefined"
      src="/assets/icons/create-document.svg"
      alt=""
      class="mx-auto w-24 h-auto -mt-10"
    />
    <img
      src="/assets/icons/document-created.svg"
      *ngIf="isDefined"
      alt=""
      class="mx-auto w-24 h-auto -mt-10"
    />
  </div>
  <svg
    *ngSwitchCase="'id'"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="mx-auto w-24 h-auto -mt-10"
    viewBox="0 0 511.999 511.999"
    style="enable-background: new 0 0 511.999 511.999"
    xml:space="preserve"
  >
    <path
      style="fill: #39a3db"
      d="M460.803,446.739H51.197C22.967,446.739,0,423.772,0,395.542V116.457
C0,88.227,22.967,65.26,51.197,65.26h409.605c28.23,0,51.197,22.967,51.197,51.197v279.084
C512,423.772,489.033,446.739,460.803,446.739z"
    />
    <path
      style="fill: #3797d3"
      d="M460.803,65.26H255.999v381.479h204.802c28.23,0,51.197-22.967,51.197-51.197V116.457
C512,88.227,489.033,65.26,460.803,65.26z"
    />
    <path
      style="fill: #80d0e1"
      d="M237.413,381.144H81.291c-10.264,0-18.586-8.322-18.586-18.586V149.439
c0-10.264,8.322-18.586,18.586-18.586h156.122c10.264,0,18.586,8.322,18.586,18.586v213.119
C255.999,372.824,247.679,381.144,237.413,381.144z"
    />
    <g>
      <circle style="fill: #e8f2fb" cx="159.356" cy="237.355" r="27.204" />
      <path
        style="fill: #e8f2fb"
        d="M160.414,285.559c-40.261,0-73.306,30.9-76.71,70.279c-0.312,3.614,2.556,6.721,6.184,6.721h141.053
 c3.628,0,6.496-3.106,6.184-6.721C233.72,316.46,200.676,285.559,160.414,285.559z"
      />
    </g>
    <g>
      <path
        style="fill: #51b3da"
        d="M439.004,234.586h-147.18c-10.264,0-18.586-8.322-18.586-18.586v-66.561
 c0-10.264,8.322-18.586,18.586-18.586h147.18c10.264,0,18.586,8.322,18.586,18.586v66.561
 C457.59,226.265,449.269,234.586,439.004,234.586z"
      />
      <path
        style="fill: #51b3da"
        d="M439.004,332.821h-147.18c-10.264,0-18.586-8.322-18.586-18.586s8.322-18.586,18.586-18.586h147.18
 c10.264,0,18.586,8.322,18.586,18.586C457.59,324.5,449.269,332.821,439.004,332.821z"
      />
      <path
        style="fill: #51b3da"
        d="M439.004,381.144h-147.18c-10.264,0-18.586-8.322-18.586-18.586s8.322-18.586,18.586-18.586h147.18
 c10.264,0,18.586,8.322,18.586,18.586C457.59,372.824,449.269,381.144,439.004,381.144z"
      />
      <path
        style="fill: #51b3da"
        d="M439.004,284.497h-147.18c-10.264,0-18.586-8.322-18.586-18.586s8.322-18.586,18.586-18.586h147.18
 c10.264,0,18.586,8.322,18.586,18.586C457.59,276.177,449.269,284.497,439.004,284.497z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <div class="py-4 font-light text-base px-2">{{ title }}</div>

  <!-- <input type="file" class="opacity-0" /> -->
</div>
